import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import AdminNav from '../AdminNav/AdminNav'
import Navbar from '../Navbar/Navbar'
import './../AdminNav/admincommon.css'
import { AdminGetBOQs, AdminGetCSV } from '../API/urls'
import { Table } from 'reactstrap'
import { configuredToastSuccess } from '../toast/toast'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileSaver from 'file-saver'


const BOQAdmin = () => {
    const initialBackDate = new Date();
    initialBackDate.setDate(initialBackDate.getDate() - 60);
    const [products, setproducts] = useState([])
    const [startDate, setStartDate] = useState(initialBackDate);
    const [endDate, setEndDate] = useState(new Date());

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    useEffect(() => {
        getDashboardData();
    }, [])

    const downloadReport = () => {
        if (startDate && endDate) {
            downloadCSV();
        }
        else {
            configuredToastSuccess("Please select a date range of the csv you want to download.", "!!")
        }
    }

    const downloadCSV = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` }
        };
        console.log(formatDate(startDate));

        await fetch(AdminGetCSV(formatDate(startDate), formatDate(endDate)), requestOptions)
            // Converting to JSON
            .then(response =>
                FileSaver.saveAs(response.url, "adminBOQ.xlsx"),
                configuredToastSuccess("CSV Downloaded!", "!!")
            )
            .catch((error) => {
                console.log(error)
            });
    }

    const getDashboardData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(AdminGetBOQs, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success || result.status) {
                    //CODE FOR GETTING ONLY UNIQUE BOQ IDs IN CASE DUPLICATES ARE PRESENT
                    let uniqueBOQHelper = new Set();
                    let BOQArray = []
                    result.data.map(item => {
                        if (!(uniqueBOQHelper.has(item.BOQ_id))) {
                            BOQArray.push(item)
                            uniqueBOQHelper.add(item.BOQ_id)
                        }
                    })
                    setproducts(BOQArray)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true} />
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav active="nav_item_2" />
                    </div>

                    <div className="border-4 border-black rounded-md w-5/6  overflow-y-auto overflow-x-hidden h-max adminMainContainer">

                        <div className="mx-8 mt-8 flex justify-between items-center">
                            <h1 className="adminHeaderH1">BOQ</h1>
                            <div className="flex justify-center items-end">
                                <div>Start Date
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={new Date()} />
                                </div>
                                <div>End Date
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} maxDate={new Date()} />
                                </div>
                                <button
                                    style={{ borderRadius: 4, maxWidth: '200px' }}
                                    className="bg-black hover:bg-black-700 text-white text-l xs:text-xs font-bold py-2 px-4 mt-3 xs:px-4"
                                    onClick={downloadReport}>
                                    Get CSV
                                </button>
                            </div>
                        </div>
                        <div className="my-4 mx-8">
                            <Table borderless responsive>
                                <thead>
                                    <tr>
                                        <th>Featured Image</th>
                                        <th>Dealer Name & BOQ Id</th>
                                        <th>Customer Name</th>
                                        <th>Date & Time</th>
                                        <th>Price</th>
                                        <th>PDF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        products.map((item, index) => (
                                            <tr key={index} className="mb-3">
                                                <td>
                                                    <img src={item.image} alt="preview" className="border-2 border-gray-400 rounded-md" style={{ width: '100%', maxWidth: '120px', height: "auto" }} />
                                                </td>
                                                <td>
                                                    <h1 className="text-lg font-bold">{item.Dealer_Name}</h1>
                                                    <h1 className="text-lg">BOQ {item.BOQ_id}</h1>
                                                </td>
                                                <td className="font-bold" >{item.Customer_Name}</td>
                                                <td>{item.Date}</td>
                                                <td className="font-bold">{item.Price}</td>
                                                <td> <a href={`${item.PDF}`} download>
                                                    <img src="../../../download.svg" style={{ maxWidth: '40px' }} alt="" />
                                                </a></td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div >
            : <Redirect to='/' />
    )
}

export default BOQAdmin
