import { Media } from "reactstrap";
import React, { useState } from "react";
import { AdminAddCollateral } from "../API/urls";
import { Document, Page } from "@react-pdf/renderer";
import ReactPlayer from "react-player";
import { configuredToastSuccess } from "../toast/toast";
import Loader from "../Loader";

const AdminAddProductModal = ({
    onCancel,
    show,
    getDashboardData
}) => {
    const initialState = {
        name: "",
        asset: "",
        type: "",
        description: ""
    }
    const [showFlag, setshowFlag] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(true)
    const [loader, setLoader] = useState(false)
    const [formInfo, setformInfo] = useState(initialState)

    const onImageChange = (event) => {
        const setType = (type) => {
            if (type === "application/pdf") return 3
            else if (type === "video/mp4") return 2
            else if (type === "image/png" || type === "image/jpeg") return 1
        }
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0]
            setformInfo({
                ...formInfo,
                asset: file,
                type: setType(file.type)
            })
            setSubmitDisable(false)
        }
        console.log("MEDIA : ", event.target.files[0])
    }

    const validateImage = () => {
        if(formInfo.asset.type==="image/jpeg" || formInfo.asset.type==="image/png" || formInfo.asset.type==="image/jpg") {
            return 1
        }
        return 0
    }
    
    const handleInvalidMedia = () => {
        configuredToastSuccess("Invalid Media File", "!!")
        setformInfo({...formInfo, asset: ""})
        document.getElementById("media").value = ""
        return (<div></div>)
    }

    const handleTerminate = () => {
        setformInfo({...initialState})
        onCancel()
        setshowFlag(false)
    }

    const handleInputChange = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        setformInfo({ ...formInfo, [key]: val })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addProductToServer()
        if (showFlag) handleTerminate()
    }

    const addProductToServer = async () => {
        setSubmitDisable(true)
        setLoader(true)
        let token = localStorage.getItem('token');
        var formdata = new FormData();
        Object.keys(formInfo).map(item => {
            formdata.append(item, formInfo[item]);
        })

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: { "Authorization": `Bearer ${token}` },
        };

        console.log("BODY OF REQ : ", formInfo)

        await fetch(AdminAddCollateral, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT :", result)
                if (result.status)
                    configuredToastSuccess(result.data, "!!")
                    getDashboardData()
                    setLoader(true)

                    // setSubmitDisable(false)
            })
            .catch((error) => {
                // console.log("catch", error)
            });
    }


    if (show === false) return <></>
    else
        return (
            <>
                <div
                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    ADD COLLATERAL
                                </h3>
                                <button
                                    className="ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={handleTerminate}
                                >
                                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        X
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className="-mx-3 mb-6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 px-2" for="media">
                                            MEDIA
                                        </label>
                                        <input type="file" onChange={onImageChange} className="border-0 mb-3" id="media" required/>
                                        {
                                            formInfo.asset && <button onClick={() => {
                                                setformInfo({ ...formInfo, asset: "" })
                                                document.getElementById("media").value = ""
                                            }}>X</button>
                                        }
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                            {console.log("FILETYPE : ", formInfo)}
                                            {
                                                formInfo.asset !== "" ? 
                                                    formInfo.asset.type === "application/pdf" || formInfo.asset.type === "video/mp4" ?
                                                    <div></div> :
                                                     validateImage() ?
                                                <Media src={`${URL.createObjectURL(formInfo.asset)}`} className="img-fluid" /> : handleInvalidMedia()
                                                
                                                : <div></div>
                                            }
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-1">


                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    name
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-sku-code"
                                                    type="text"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formInfo.name}
                                                    onChange={handleInputChange}
                                                    required
                                                ></input>
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                                                    Description
                                                </label>
                                                <textarea
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-model"
                                                    type="text"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={formInfo.description}
                                                    onChange={handleInputChange}
                                                >
                                                </textarea>
                                            </div>
                                        </div>

                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        {/* <button
                                            className="border-2 border-black rounded-md mr-4 text-white bg-black font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Save & Add More
                                        </button> */}
                                        <button
                                            className="border-2 border-black rounded-md mr-4 text-white bg-black font-bold uppercase px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex items-center"
                                            type="submit"
                                            disabled={submitDisable}
                                            onClick={() => setshowFlag(true)}
                                        >
                                            <Loader loader={loader}/>
                                            <span>
                                                Save & Close
                                            </span>
                                        </button>
                                        <button
                                            className="border-2 border-red-600 rounded-md text-white bg-red-600 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mr-4"
                                            type="button"
                                            onClick={handleTerminate}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        );
}

export default AdminAddProductModal;
