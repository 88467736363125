import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import home from '../../assets/KohlerLogo/KohlerLogo.png'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { all_product } from '../API/urls'

const Homepage = ({ setData }) => {

    const [loading, toggleLoading] = useState(false)
    let history = useHistory()

    const handleProduct = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json", "Authorization": `Bearer ${token}`
            },
        };

        await fetch(all_product, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {

                if (result.success || result.status) {
                    // console.log("6666", result)
                    setData(result.data)
                    toggleLoading(false)
                    history.push('/results')
                }
                else {
                    // console.log("6666", result)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }
    return (
        localStorage.getItem('isLoggedIn') ?
            <div className="overflow-x-hidden max-h-screen">
                <Navbar />
                <div className="my-20 xs:my-52 2xl:flex 2xl:flex-col 2xl:justify-center">
                    <div className="flex flex-col justify-center 2xl:content-center 2xl:items-center">
                        <div className="md:flex md:justify-center xs:flex xs:justify-center lg:flex lg:justify-center xl:flex xl:justify-center mx-4">
                            <img className=" 2xl:h-48 xs:h-32 sm:w-1/2 sm:h-32 sm:mx-auto  md:h-32 lg:h-40 xl:h-40 " src={home} alt="logo" />
                        </div>
                        <div className="flex flex-col 2xl:justify-center 2xl:text-3xl 2xl:my-8 2xl:gap-2 xl:mx-auto xl:text-4xl xl:my-4 lg:mx-auto lg:text-4xl lg:my-4 md:mx-auto md:text-4xl md:my-4 sm:mx-auto sm:text-2xl sm:my-4 xs:mb-16 xs:mx-auto xs:text-xl xs:m-4">
                            <h1 className="xs:leading-3">Choose from wide range of</h1>
                            <h1 className="xs:leading-3">designs and configurations</h1>
                        </div>
                    </div>
                    <div className="flex justify-center 2xl:gap-16 xl:gap-16 lg:gap-16 md:gap-12 sm:gap-16 xs:gap-6 xs:mx-8 ">
                        <Link to="/question1">
                            <button className="bg-black text-white 2xl:text-lg xs:px-10 xs:py-3 sm:text-md 2xl:px-12 md:px-12 xs:text-xs font-bold sm:py-4 sm:px-12 md:py-4 lg:px-16 2xl:py-3 lg:py-4 xl:px-16 xl:py-4 rounded-md text-xl border-black border-4">Start</button>
                        </Link>
                        <button className=" bg-white text-black 2xl:text-lg text-xl sm:text-md xs:text-xs font-bold 2xl:px-4 2xl:py-3 xs:py-3 xs:px-4 sm:py-4 sm:px-4 md:py-4 md:px-2 lg:px-6 lg:py-4 xl:px-8 xl:py-4 rounded-md border-black" style={{ border: '3px black solid' }} onClick={handleProduct}>Products Page</button>
                    </div>
                </div>
            </div>
            : <Redirect to="/" />
    )
}

export default Homepage
