import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import pict from '../../assets/KohlerLogo.png'
import './Login.css';
import { login } from '../API/urls'
import { configuredToastSuccess } from '../toast/toast';


const Login = ({ input, setInput }) => {
  const [loading, toggleLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('type') === '1') {
        history.push('/adminDash')
      } else if (localStorage.getItem('type') === '2') {
        history.push('/adminDash')
      }

      else {
        history.push('/dashboard')
      }
    }
    return () => {

    }
  }, [])


  const loader = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse">Data is Loading ...</div>
    </div>
  )
  let history = useHistory();

  const showInput = async () => {  

    toggleLoading(true)
    var formdata = new FormData();
    formdata.append("mobile_no", "+91"+input);

    var requestOptions = {
      method: 'POST',
      body: formdata
    };

    await fetch(login, requestOptions)
      .then(response => response.json())
      .then((result) => {
        console.log(result);
       
        if (result.status) { 
          localStorage.setItem("account_status",result.data.account_status)
          if (!result.data.admin && !result.data.dealer && !result.data.customer) {
            configuredToastSuccess(`Number not registered`, "!!");
          }
          else {
            console.log(result)
            localStorage.setItem('mobile', input)
            toggleLoading(false)
            setInput("")
            history.push('/otp');
          }
        }
        else {
          console.log(result.data)
          configuredToastSuccess(`${result.data}`, "!!")
          toggleLoading(false)
        }
      })
      .catch((error) => {
        console.log("data = ", error.status)
        toggleLoading(false)
      });

    toggleLoading(false)
  }

  return (
    loading
      ? loader
      : <div className="overflow-y-hidden">
        <div className="grid grid-cols-2 overflow-y-hidden h-screen xl:flex xl:justify-center 2xl:flex 2xl:justify-center gap-14 lg:flex lg:justify-center">
          <div className="col-span-1 xl:w-3/5 2xl:flex 2xl:justify-center xl:flex xl:justify-center lg:flex lg:justify-center loginImageContainer">
            <img className="self-center 2xl:w-4/5 xl:w-4/5 lg:w-4/5 w-5/6" src={pict} alt="kohler" />
          </div>
          <div className="col-span-2 self-center" >
            <div className=" text-center align-center border-black border-4 rounded-md px-9 mx-5 loginInnerMainContainer" >
              <div className=" flex justify-center login">
                Login
              </div>
              <input className="shadow appearance-none border-gray border-2 px-3 rounded py-4 text-left text-black-700 leading-tight focus:shadow-outline my-6"
                type="tel"
                placeholder="Mobile Number"
                value={input}
                onChange={(e) => setInput(e.target.value)} />
              <br /><br />
              <button className="bg-black text-white px-8 py-3 text-xl rounded-md" onClick={showInput}>Continue</button>
              <h1 className="text-lg">New Here?<Link to="/register"><span className="font-bold ml-2">Register</span></Link></h1>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Login
