import React, { useState, useEffect, useContext } from 'react';
import Register from './components/register/Register'
import Login from './components/Login/Login';
import Otp from './components/otp/Otp'
import { Route, useHistory } from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard';
import Collateral from './components/Collateral/Collateral';
import Dealer from './components/Dealer-info/Dealer';
import Homepage from './components/Homepage/Homepage';
import AdminDash from './components/Admin-dash/AdminDash';
import BOQAdmin from './components/BOQAdmin/BOQAdmin';
import Products from './components/Products/Products';
import CollateralAdmin from './components/CollateralsAdmin.js/CollateralAdmin';
import CollateralDetails from './components/CollateralsAdmin.js/CollateralDetails';
import ProductDetails from './components/Cart/ProductDetails';
import Cart from './components/Cart/Cart';
import Share from './components/share/Share'
import Calender from './components/calender/Calender';
import Question1 from './components/Questions/Question1';
import Question2 from './components/Questions/Question2';
import Results from './components/results/Results';
import Question3 from './components/Questions/Question3';
import Question4 from './components/Questions/Question4';
import Question5 from './components/Questions/Question5';
import Question6 from './components/Questions/Question6';
import Filter from './components/Filters/Filter'
import ProductDisplay from './components/ProductDisplay/Display'
import { all_product, filterOptionsImage } from './components/API/urls';
import ShareCart from '../src/components/Cart/ShareCart'
import { BrowserRouter, Switch } from 'react-router-dom';
import { ProductContext } from './helpers/products/productContext';
import Dealers from './components/Dealers/Dealers';
import Users from './components/Users/Users';
import AuthContext from './components/context/AuthContent';
import Banners from './components/Banners/Banners';


function App() {
   const [input, setInput] = useState('')
   const [select, setSelect] = useState('')
   const [dealer, setDealer] = useState([])
   const [fields, setFields] = useState({})
   const productContext = useContext(ProductContext);
   const [imagesObj, setimages] = useState()
   const [show, setShow] = useState([0]);
   const [data, setData] = useState([])
   const [dataFiltered, setDataFiltered] = useState([])

   const authCtx = useContext(AuthContext);

   const [filterFields, setFilterFields] = useState({
      "type": [],
      "config": [],
      "Runnning_Length": [],
      "opening": [],
      "Finish": [],
      "height": [],
      "glass_thickness": [],
      "pattern": [],
      "glassType": [],
      "accessories": [],
   })

   let history = useHistory()
   const [loading, setloading] = useState(false)

   useEffect(() => {
      handleProduct();
   }, [productContext.triggerDataLoad])

   useEffect(() => {
      getImages();
   }, [productContext.triggerImages])



   const getImages = async () => {
      await setloading(true)
      let token = localStorage.getItem('token')
      var requestOptions = {
         method: 'GET',
         headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
         },
      };

      await fetch(filterOptionsImage, requestOptions)
         .then(response => {
            console.log("data = ", response.status)
            if (response.status === 401) {
               localStorage.clear()
               localStorage.setItem('isLoggedIn', false)
               history.push('/')
            }
            setloading(false)
            return response.json()
         })

         .then(async (result) => {
            if (result.success || result.status) {
               await setimages(result.data)
               await productContext.setImages(result.data)
               console.log(imagesObj, result.data)
            }
         })
         .catch((error) => {
            console.log("data = ", error.status)
            // console.log(error)
         });

      await setloading(false)
   }


   const handleProduct = async () => {
      let token = localStorage.getItem('token')
      var requestOptions = {
         method: 'GET',
         headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
         },
      };

      await fetch(all_product, requestOptions)
         .then(response => {
            console.log("data = ", response.status)
            if (response.status === 401) {
               localStorage.clear()
               localStorage.setItem('isLoggedIn', false)
               history.push('/')
            }
            return response.json()
         })
         .then(async (result) => {

            if (result.success || result.status) {
               // console.log(result.data)
               await productContext.handleFilter(result.data)
               setFilterFields(productContext.filterFields)
               setDataFiltered(result.data)
               setData(result.data)
               productContext.setProductData(result.data)
               // console.log(data)
               // console.log(filterFields)
            }
         })
         .catch((error) => {
            console.log("data = ", error.status)
            // console.log(error)
         });
   }

   return (
      <div>
         <BrowserRouter>
            <Switch>
               <Route exact path="/">
                  <Login input={input} setInput={setInput} />
               </Route>
               <Route path="/register">
                  <Register
                     select={select}
                     setSelect={setSelect}
                     dealer={dealer}
                     setDealer={setDealer}
                  />
               </Route>
               <Route path="/otp">
                  <Otp handleProduct={handleProduct} />
               </Route>
               <Route path="/dealer-info">
                  <Dealer />
               </Route>
               {/* <Route path="*">
                  <Redirect to="/" />
               </Route> */}

               {
                  authCtx.type === 1 &&
                  <>
                     <Route path="/adminDash">
                        <AdminDash />
                     </Route>
                     <Route path="/BOQ-admin">
                        <BOQAdmin />
                     </Route>
                     <Route path="/banners">
                        <Banners />
                     </Route>
                     <Route path="/Products">
                        <Products />
                     </Route>
                     <Route path="/Dealers">
                        <Dealers />
                     </Route>
                     <Route path="/Users">
                        <Users />
                     </Route>
                     <Route path="/CollateralAdmin">
                        <CollateralAdmin />
                     </Route>
                  </>
               }
               {
                  authCtx.type === 0 &&
                  <>
                     <Route path="/Collateral">
                        <Collateral />
                     </Route>
                     <Route path="/dashboard">
                        <Dashboard />
                     </Route>
                     <Route path="/homepage">
                        <Homepage
                           setData={setData}
                        />
                     </Route>
                     <Route path="/CollDetails">
                        <CollateralDetails />
                     </Route>
                     <Route path="/Product-detail">
                        <ProductDetails />
                     </Route>
                     <Route path="/Cart">
                        <Cart />
                     </Route>
                     {/* <Route path="/Banner">
                        <Banner />
                     </Route>
                     <Route path="/calender">
                        <calender />
                     </Route> */}
                     <Route path="/share">
                        <Share />
                     </Route>
                     <Route path="/calender">
                        <Calender />
                     </Route>
                     <Route path="/question1">
                        <Question1
                           data={data}
                           setFields={setFields}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                           setimages={setimages}
                        />
                     </Route>
                     <Route path="/question2">
                        <Question2
                           data={data}
                           fields={fields}
                           setFields={setFields}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                           setimages={setimages}
                        />
                     </Route>
                     <Route path="/question3">
                        <Question3
                           data={data}
                           fields={fields}
                           setFields={setFields}
                           show={show}
                           setShow={setShow}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                           setimages={setimages}
                        />
                     </Route>
                     <Route path="/question4">
                        <Question4
                           data={data}
                           fields={fields}
                           setFields={setFields}
                           show={show}
                           setShow={setShow}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                           setimages={setimages}
                        />
                     </Route>
                     {/* <Route path="/question5">
                        <Question5
                           data={data}
                           fields={fields}
                           setFields={setFields}
                           show={show}
                           setShow={setShow}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                           setimages={setimages}
                        />
                     </Route> */}
                     <Route path="/question5">
                        <Question6
                           data={data}
                           fields={fields}
                           setFields={setFields}
                           setimages={setimages}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           imagesObj={imagesObj}
                        />
                     </Route>
                     <Route path="/results">
                        <Results
                           fields={fields}
                           data={data}
                           setData={setData}
                           setFields={setFields}
                           setDataFiltered={setDataFiltered}
                           dataFiltered={dataFiltered}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           show={show}
                           setShow={setShow}
                        />
                     </Route>
                     <Route path="/filters-mob">
                        <Filter
                           filter={fields}
                           setFields={setFields}
                           setData={setData}
                           setDataFiltered={setDataFiltered}
                           dataFiltered={dataFiltered}
                           data={data}
                           filterFields={filterFields}
                           setFilterFields={setFilterFields}
                           show={show}
                           setShow={setShow}
                        />
                     </Route>
                     <Route path="/display">
                        <ProductDisplay
                           fields={fields}
                           setFields={setFields}
                           data={data}
                           setData={setData}
                           setDataFiltered={setDataFiltered}
                           dataFiltered={dataFiltered}
                           show={show}
                           setShow={setShow}
                        />
                     </Route>
                     <Route path="/shareCart/:id">
                        <ShareCart />
                     </Route>
                  </>
               }

            </Switch>
         </BrowserRouter>
      </div >
   );
}

export default App;
