
const { toast } = require('tailwind-toast')


const configuredToastSuccess = (title, message) => {
    toast()
        .success(title, message)
        .with({
            duration: 1000,
            speed: 1000,
            positionX: 'end',
            positionY: 'top',
            color: 'bg-gray-300',
            
        }).show()
}

module.exports = {
    configuredToastSuccess
}