import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { EditProfile, logout, profile } from '../API/urls'
import { configuredToastSuccess } from '../toast/toast'

const Dealer = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [profileData, setProfileData] = useState('')
    let history = useHistory()
    const [loading, toggleLoading] = useState(false)

    const handleClick = async () => {
        toggleLoading(true)

        let token = localStorage.getItem('token')

        var requestOptions = {
            method: 'POST',
            headers: {
                "Accept": "application/json", "Authorization": `Bearer ${token}`
            },
        };

        await fetch(logout, requestOptions)
            .then(response => response.json())
            .then((result) => {

                // console.log(result)
                localStorage.clear()
                localStorage.setItem('isLoggedIn', false)
                history.push('/')
                toggleLoading(false)

            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log("catch", error)
                toggleLoading(false)

            });
        toggleLoading(false)

    }

    useEffect(async () => {
        await handleDealerProfile()
        
    }, [])

    const handleDealerProfile = async () => {
        toggleLoading(true)

        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json", "Authorization": `Bearer ${token}`
            },
        };

        await fetch(profile, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                return response.json()
            })
            .then(async(result) => {
                console.log(result)
                if (result.success || result.status) {
                    await setName(result.data.name)
                    await setProfileData(result.data)
                    await setEmail(result.data.email)
                }
                toggleLoading(false)

            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }

    const editUser = async () => {
        let token = localStorage.getItem('token')

        var formdata = new FormData();
        formdata.append("email", email);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };

        await fetch(EditProfile, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                return response.json()
            }).then((result) => {

                if (result.status)
                    configuredToastSuccess(result.data, "!!")
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log("catch", error)
            });
    }

    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden">
                <Navbar />
                <div className="my-14 2xl:mt-8 flex justify-center">
                    <div className="border-black border-2  w-2/5 md:w-3/5 sm:w-3/5 xs:w-4/5 p-10 rounded-md xs:m-6 sm:p-5 xs:flex xs:flex-col xs:justify-center">
                        <Link to="/AdminDash">
                            <h1 className="text-xl xs:text-3xl mt-6" style={{ fontWeight: 600, textAlign: 'center', fontSize: 30 }}>{name}</h1>
                        </Link>
                        <h2 className="text-lg my-4" style={{ textAlign: 'center' }}>{profileData.location}</h2><br /><br />
                        <div className="flex flex-col justify-center">

                            <div className=" flex flex-start" style={{ borderBottomWidth: 1, borderBottomColor: "#47315a", marginBottom: 30, backgroundColor: '#e3e3e3' }}>
                                <input style={{ border: 'none', fontSize: 20 }} className="px-6 py-3 xs:px-4 w-5/6" type="text" placeholder="Name" value={name} disabled />
                            </div>

                            <div className=" flex flex-start" style={{ borderBottomWidth: 1, borderBottomColor: "#47315a", marginBottom: 30, backgroundColor: '#e3e3e3' }}>
                                <input style={{ border: 'none', fontSize: 20 }} className="px-6 py-3 xs:px-4 w-5/6 " type="text" placeholder="Mobile Number" value={profileData.mobile_no} disabled />
                            </div>

                            <div className=" flex flex-start items-center" style={{ borderBottomWidth: 1, borderBottomColor: "#47315a", marginBottom: 30 }}>
                                <input style={{ border: 'none', fontSize: 20 }} className=" px-6 py-3 xs:px-4 w-5/6" type="text" placeholder="Email id" value={email} onChange={(e) =>  setEmail(e.target.value) } />
                                <button style={{ outline: 'none' }} onClick={() => editUser()}>
                                    <h1 className="font-bold" style={{ fontSize: 20, paddingTop: 0 }}>Save</h1>
                                </button>
                            </div>

                        </div>
                        <div className="flex justify-center 2xl:mt-4" style={{ marginTop: 45 }}>
                            <button className="bg-black hover:bg-black-700 text-white  font-bold py-4 px-12 rounded" onClick={handleClick}>Log out</button>
                        </div>
                    </div>
                    <br />
                </div>

            </div>

            : <Redirect to='/' />
    )
}

export default Dealer
