import React, { useState, useEffect, useRef } from 'react'
import './Col.css'
import { Redirect, useHistory } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Navigation from '../Navigation/Navigation'
import { get_collaterals, AdminGetCollateral } from '../API/urls'
import ReactPlayer from "react-player"
// import { Document, Page, } from 'react-pdf';
import Modal from '../Modal/Modal'

const Collateral = () => {
    const [loading, toggleLoading] = useState(false)
    const [Collateral, setCollateral] = useState([])
    const [modal, setModal] = useState(false)
    const [videoDimension, setVideoDimension] = useState({})

    let history = useHistory()
    const videoElem = useRef(null)

    const toggleModal = () => {
        setModal(!modal)
    }

    useEffect(() => {
        handleProduct()
    }, [])

    useEffect(() => {
        if (videoElem.current != null) {
            const width = videoElem.current.parentElement.offsetWidth
            const height = videoElem.current.parentElement.offsetHeight

            setVideoDimension({
                width,
                height
            })
        }
    }, [Collateral])


    const handleProduct = async () => {
        toggleLoading(true)
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json", "Authorization": `Bearer ${token}`
            },
        };

        await fetch(AdminGetCollateral, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {

                if (result.status) {
                    setCollateral([...result.data])
                    toggleLoading(false)
                }
                else {
                    // alert(`${result}`)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }

    return (
        localStorage.getItem('isLoggedIn') ?
            <div className="overflow-x-hidden overflow-y-hidden lg:h-full ">
                <Navbar />

                <div className="flex w-full">
                    <div className="flex flex-row overflow-y-auto max-h-screen mb-6 mainDirection mainContainer w-full">
                        <div className="flex flex-col justify-start">
                            <Navigation modal={modal} toggleModal={toggleModal} active="nav_item_2" />
                        </div>
                        <div className="flex flex-col h-auto overflow-y-auto collateralMain column-4">

                            {
                                Collateral.map((ele) =>
                                    <div style={{ marginTop: 20 }} className="collateralInnerListing">
                                        {
                                            (ele.extension === 'Video')
                                                ?   <div className="border-black border-2 collateralInnerListingMain wrapper">
                                                        <video
                                                            ref={videoElem}
                                                            src={ele.asset}
                                                            controls={true}
                                                            style={{
                                                                width: videoDimension.width || 0,
                                                                height: videoDimension.height || 0
                                                            }}
                                                        />
                                                    </div>
                                                : (ele.extension === 'Image')
                                                    ? <img className="border-black border-2 collateralInnerListingMain wrapper"
                                                        src={ele.asset}
                                                        alt="logo"
                                                    />
                                                    : <div className="border-black border-2 collateralInnerListingMain wrapper">
                                                        <a href={ele.asset} target="_blank">
                                                            <div
                                                                className="w-full flex justify-end h-full"
                                                                style={{ backgroundImage: `url('../../../pdf.png')`, backgroundSize: '100% 100%',backgroundPosition:'center center no-repeat' }}
                                                            >
                                                                    {/* <img className="2xl:w-12 w-10 self-end  mt-44" src={pin} alt="pin" /> */}
                                                            </div>
                                                        </a>
                                                    </div>
                                        }

                                        <div>

                                            <p className="dash product" >{ele.name}</p>
                                            <p className="dash">{ele.extension}</p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>

                    </div>
                </div>
                {
                    modal && (
                        <Modal modal={modal} toggleModal={toggleModal} />
                    )
                }
            </div>

            : <Redirect to='/' />


    )
}

export default Collateral
