import React, { useState } from 'react';
import pict from '../../assets/KohlerLogo.png'
import { Link, useHistory } from 'react-router-dom'
import { signUp } from '../API/urls';
import "./register.css"
import "./../Login/Login.css"
import Autocomplete from "./Autocomplete";
import { configuredToastSuccess } from '../toast/toast';

const Login = ({ select, setSelect, dealer, setDealer }) => {
    const [input, setInput] = useState('')
    const [mobile, setMobile] = useState('')
    const [loading, toggleLoading] = useState(false)
    const [selectedDealer, setSelectedDealer] = useState('')
    const [dealerId, setDealerId] = useState('')

    const loader = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse">Data is Loading ...</div>
        </div>
    )

    let map = {
        "Dealer": 2,
        "Customer": 3,
    }

    let history = useHistory();

    const showInput = async () => {

        if (select === "Dealer/Customer" || select === "") {
            alert(' Select type!! ')
            return
        }


        toggleLoading(true)
        // console.log(dealerId)
        var formdata = new FormData();
        formdata.append("mobile_no", "+91" + mobile);
        formdata.append("name", input);
        formdata.append("account_type", map[select]);
        formdata.append("dealer_id", dealerId);

        var requestOptions = {
            method: 'POST',
            body: formdata
        };

        await fetch(signUp, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {

                if (result.status) {
                    localStorage.setItem('mobile', mobile)
                    toggleLoading(false)
                    history.push('/otp');
                }
                else {
                    console.log(result.data)
                    configuredToastSuccess(result.data, "!!")
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }

    return (
        loading
            ? loader
            : <div className="2xl:flex 2xl:flex-col 2xl:justify-center 2xl:h-screen">
                <div className="grid grid-cols-2 h-screen  2xl:flex 2xl:justify-center gap-14 xs:gap-0 xl:flex xl:justify-center  lg:flex  lg:justify-center">
                    <div className="col-span-1 xl:w-3/5 2xl:flex 2xl:justify-center xl:flex xl:justify-center lg:flex lg:justify-center w-max h-max loginImageContainer">
                        <img className="self-center 2xl:w-4/5 xl:w-4/5 lg:w-4/5 w-3/5" src={pict} alt="pict" />
                    </div>
                    <div className="col-span-2 self-center lg:w-3/5 xl:my-10 lg:my-10 xl:flex xl:justify-center lg:flex lg:justify-center">
                        <div className="overflow-y-auto  border-black border-4 rounded-md 2xl:p-16 xl:p-10 lg:p-12 mx-5 loginInnerMainContainer">
                            <div className="flex justify-center login">
                                Signup
                            </div>
                            <div className='flex flex-col items-center'>
                                <div className="flex justify-center">
                                    <input className="shadow appearance-none border-gray-400 border-2 px-3 rounded py-4 text-left text-black-700 leading-tight focus:shadow-outline" value={input} type="text" placeholder="Full Name" onChange={(event) => setInput(event.target.value)} required />
                                </div>
                                <div className="flex justify-center my-4 select">
                                    <select className="block appearance-none bg-white border-gray-400 border-2 hover:border-black px-3 rounded shadow leading-tight focus:outline-none focus:shadow-outline" placeholder="Dealer/Customer" value={select} onChange={(event) => setSelect(event.target.value)} required>
                                        <option>Dealer/Customer</option>
                                        <option>Dealer</option>
                                        <option>Customer</option>
                                    </select>
                                </div>
                                <div className="flex justify-center">
                                    <input className="shadow appearance-none border-gray-400 border-2 px-3 rounded py-4 text-left text-black-700 leading-tight focus:shadow-outline" id="username" type="text" placeholder="Mobile Number" value={mobile} onChange={(event) => setMobile(event.target.value)} required />
                                </div>
                                {
                                    select === 'Dealer' ?
                                        <div className="suggestion-div flex flex-col self-center items-center mt-4" style={{ padding: 0, }}>
                                            <Autocomplete
                                                className="shadow appearance-none border-gray-400 border-2 px-3 rounded py-4 text-left text-black-700 leading-tight focus:shadow-outline w-full"
                                                input={selectedDealer}
                                                setInput={setSelectedDealer}
                                                dealerId={dealerId}
                                                setDealerId={setDealerId}
                                                required
                                            />
                                        </div>
                                        : setDealer("")
                                }
                                <div className="flex justify-center">
                                    <button className="bg-black text-white px-8 py-3 text-xl rounded-md my-4" onClick={showInput}
                                        disabled={select === "Dealer" && dealerId !== "" && selectedDealer !== "" && input !== "" && mobile !== "" ? false :
                                            select !== "Dealer" && input !== "" && mobile !== "" ? false : true}>
                                        Continue
                                    </button>
                                </div>

                                <div className="flex justify-center">
                                    <Link to="/" >
                                        <span className="text-xl font-bold" >Log in</span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Login
