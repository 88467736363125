import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import { login, verify_otp } from '../API/urls';
// import OTPInput from "./OTPInput/index";
import "./otp.css";
import pict from '../../assets/KohlerLogo.png';
import { configuredToastSuccess } from '../toast/toast';
import AuthContext from '../context/AuthContent';


const Otp = ({ handleProduct }) => {
    const [otpValues, setValue] = React.useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: ""
    });

    const authCtx = useContext(AuthContext);
    console.log(authCtx);
    const [loading, toggleLoading] = useState(false)

    const loader = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse">Data is Loading ...</div>
        </div>
    )


    let history = useHistory();
    const handleButton = async () => {
        // console.log(otpValues, otpValues.otp1, otpValues['otp2'])
        let otp = otpValues['otp1'] + otpValues['otp2'] + otpValues['otp3'] + otpValues['otp4']
        let mobile = localStorage.getItem('mobile')
        // console.log(otp)
        // console.log(mobile)
        var formdata = new FormData();
        formdata.append("otp", otp);
        formdata.append("mobile_no", "+91"+mobile);
        var requestOptions = {
            method: 'POST',
            body: formdata
        };

        toggleLoading(true)
        await fetch(verify_otp, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.replace('/')
                }
                return response.json()
            })
            .then(async (result) => {

                console.log("OTP = ", result)
                if (result.status || result.token) {
                    localStorage.setItem("token", result.token)
                    localStorage.setItem('isLoggedIn', true)
                    // localStorage.setItem()
                    await handleProduct();
                    toggleLoading(false)
                    setValue({
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: ""
                    })
                    if (result.account_type === 1) {
                        localStorage.setItem('type', 1)
                        console.log("Done");
                        authCtx.updateType(1);
                        history.replace('/adminDash');
                    }
                    else {
                        history.replace('/dashboard');
                        authCtx.updateType(0);
                    }
                }
                else {
                    // console.log(result.data)
                    setValue({
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: ""
                    })
                    configuredToastSuccess(`${result.data}`, "!!")
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }

    const handleChange = (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split('-')
        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 4) {
                // Get the next input field
                const nextSibling = document.querySelector(
                    `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
                );

                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }


        setValue({
            ...otpValues,
            [`${fieldName}${fieldIndex}`]: value
        })
    }

    const resendOTP = async () => {
        toggleLoading(true)
        let mobile = localStorage.getItem('mobile')
        var formdata = new FormData();
        formdata.append("mobile_no", "+91"+mobile);

        var requestOptions = {
            method: 'POST',
            body: formdata
        };

        await fetch(login, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status) {
                    toggleLoading(false)
                }
                else {
                    configuredToastSuccess(`${result.data}`, "!!")
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                toggleLoading(false)
            });
        toggleLoading(false)
    }




    return (
        loading
            ? loader
            : <div className="overflow-y-hidden">
                <div className="grid grid-cols-2 overflow-y-hidden h-screen xl:flex xl:justify-center 2xl:flex 2xl:justify-center gap-14 lg:flex lg:justify-center">
                    <div className="col-span-1 xl:w-3/5 2xl:flex 2xl:justify-center xl:flex xl:justify-center lg:flex lg:justify-center w-max h-max loginImageContainer">
                        <img className="self-center 2xl:w-4/5 xl:w-4/5 lg:w-4/5 w-3/5" src={pict} alt="kohler" />
                    </div>
                    <div className="col-span-2 self-center" >
                        <div className=" text-center align-center border-black border-4 rounded-md px-9 mx-5 xs:mb-10 sm:mb-10 md:mb-10" style={{ height: 500, paddingTop: 50, paddingBottom: 50 }}>
                            <div className=" flex justify-center login">
                                Login
                            </div>
                            <p className="block mb-2 text-xl xs:text-lg font-light text-center text-gray-700">
                                Verify with OTP
                            </p>
                            <div className="flex justify-center my-8">
                                <input focus maxLength={1} type="tel" className="mx-4 xs:mx-2 rounded-md w-16 h-16 xs:w-12 xs:h-12 text-center border-2 border-gray-400" value={otpValues['otp1']} onChange={handleChange} name="otp-1" />
                                <input maxLength={1} type="tel" className="mx-4 xs:mx-2 rounded-md w-16 h-16 xs:w-12 xs:h-12 text-center border-2 border-gray-400" value={otpValues['otp2']} onChange={handleChange} name="otp-2" />
                                <input maxLength={1} type="tel" className="mx-4 xs:mx-2 rounded-md w-16 h-16 xs:w-12 xs:h-12 text-center border-2 border-gray-400" value={otpValues['otp3']} onChange={handleChange} name="otp-3" />
                                <input maxLength={1} type="tel" className="mx-4 xs:mx-2 rounded-md w-16 h-16 xs:w-12 xs:h-12 text-center border-2 border-gray-400" value={otpValues['otp4']} onChange={handleChange} name="otp-4" />
                            </div>
                            <button className="bg-black text-white px-8 xs:px-4 md:px-4 py-3 text-xl md:text-lg xs:text-lg rounded-md" onClick={handleButton}>Continue</button>
                            <h1 className="text-lg"><button className="font-bold ml-2" onClick={resendOTP}>Resend OTP</button></h1>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Otp
