import React, {useState, useEffect} from 'react'
import './navigation.css'
import { add_new_BOQ } from '../API/urls'
import { useHistory, Link } from 'react-router-dom'

const Navigation = ({ modal, toggleModal, active }) => {
    const [activeNavId, setactiveNavId] = useState(active)

    let history = useHistory()
    const newBOQ = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: { 
"Accept": "application/json",  "Authorization": `Bearer ${token}` },
        };

        await fetch(add_new_BOQ, requestOptions)
            .then(response => {
            console.log("data = ", response.status)
            if(response.status === 401){
                localStorage.clear()
               localStorage.setItem('isLoggedIn', false)
               history.push('/')
            }
            return response.json()
         })         
            .then((result) => {

                if (result.success || result.status) {
                    localStorage.setItem('boq_id', result.data.boq_id)
                    // console.log("navigation create proposal boq")
                    history.push('/homepage')
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
console.log("data = ", error.status)
                // console.log(error)
            });
    }


    const exsitingBOQ = async () => {
        let boq_id = localStorage.getItem('boq_id')
        if (boq_id) {
            toggleModal()
        }
        else {
            let token = localStorage.getItem('token')
            var requestOptions = {
                method: 'GET',
                headers: { 
"Accept": "application/json",  "Authorization": `Bearer ${token}` },
            };

            await fetch(add_new_BOQ, requestOptions)
                .then(response => {
            console.log("data = ", response.status)
            if(response.status === 401){
               localStorage.setItem('isLoggedIn', false)
               localStorage.clear()
               history.push('/')
            }
            return response.json()
         })         
                .then((result) => {

                    if (result.success || result.status) {
                        localStorage.setItem('boq_id', result.data.boq_id)
                        // console.log("navigation create proposal boq")
                        history.push('/results')
                    }
                    else {
                        // alert(`${result}`)
                    }
                })
                .catch((error) => {
console.log("data = ", error.status)
                    // console.log(error)
                });
        }
    }

    const toggleNavActive = (id) => {
        document.getElementById(activeNavId).classList.remove("navi-active")
        setactiveNavId(id)
        document.getElementById(id).classList.add("navi-active")
    }

    useEffect(() => {
        toggleNavActive(activeNavId)
    })

    // const navLinkClasses = "navi-n text-2xl xl:text-xl xs:text-xs sm:text-lg 2xl:mx-8  md:mx-12 sm:mx-12 xs:ml-4"
    const navLinkClasses = "navi-n"

    return (
        <div className="navParent">
            <Link to="/dashboard">
                <p 
                    className={`${navLinkClasses}`} 
                    id="nav_item_1" 
                    onClick={(e) => toggleNavActive("nav_item_1")}>Home
                </p>
            </Link>
            <Link to="/Collateral">
                <p 
                    className={`${navLinkClasses}`} 
                    id="nav_item_2"
                    onClick={(e) => toggleNavActive("nav_item_2")}>Collaterals
                </p>
            </Link>
            {/* <button style={{ outline: 'none' }} onClick={newBOQ}>
                <p 
                    className={`${navLinkClasses}`} 
                    id="nav_item_3"
                    onClick={(e) => toggleNavActive("nav_item_3")}>Create Proposal
                </p>
            </button>
            <button style={{ outline: 'none' }} onClick={exsitingBOQ}>
                <p 
                    className={`${navLinkClasses}`} 
                    id="nav_item_4"
                    >Product Page
                </p>
            </button> */}
            <Link to={{pathname:"https://kohler.servitiumcrm.com/kohler/showerEnclosure/showerEnclosure.jsp"}} target='_blank' ><p className={`${navLinkClasses}`} id="5">Track Here</p></Link>
        </div>
    )
}

export default Navigation
