import React from 'react'
import upload from '../../assets/Group 80.svg'
import cross from '../../assets/Group 8.svg'
import { Link, Redirect } from 'react-router-dom'

const CollateralDetails = () => {
    return (
        localStorage.getItem('isLoggedIn')
            ? <div style={{ overflowY: 'hidden' }}>
                <div className="border-black border-4 mx-auto my-8" style={{ width: 500, height: 627, borderRadius: 6 }}>
                    <div style={{ marginLeft: 600 }} className="mt-6"><Link to="/CollateralAdmin"><img src={cross} alt="cross" /></Link></div>
                    <div className="border-gray-300 border-2 mx-auto my-2" style={{ width: 325, height: 180 }} >
                        <img className="mx-auto my-16" src={upload} alt="upload" />
                    </div>
                    <div className="my-4">
                        <input className="appearance-none border-gray-400 border-2 mt-4 text-xl mx-16 py-4 px-6 text-black-700 leading-tight  focus:shadow-outline" type="text" placeholder="Collateral Name" style={{ width: '75%', borderRadius: 6 }} /><br />
                        <textarea className="mx-16 my-4 text-xl border-gray-400 border-2 h-40 px-6" type="text" placeholder="Details" style={{ width: '75%', borderRadius: 6 }} />
                    </div>
                    <div className="flex justify-center">
                        <button className="bg-black hover:bg-black-700 text-white text-xl font-bold py-4 px-12 " style={{ borderRadius: 6 }}>Upload</button>
                    </div>

                </div>
            </div>
            : <Redirect to="/" />
    )
}

export default CollateralDetails
