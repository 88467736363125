import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import pin from '../../assets/Group 123.svg'
import AdminNav from '../AdminNav/AdminNav'
import Navbar from '../Navbar/Navbar'
import './../AdminNav/admincommon.css'
import AdminProductDetailModal from './AdminProductDetailModal'
import AdminAddProductModal from './AdminAddCollateralModal'
import AdminProductDeleteModal from './AdminCollateralDelete'
import AdminEditProductModal from './AdminEditCollateralModal'
import { configuredToastSuccess } from '../toast/toast'
import { AdminDeleteCollateral, AdminGetCollateral } from '../API/urls'
import ReactPlayer from "react-player"
import EditIcon from './../../assets/edit-icon.png'
import DeleteIcon from './../../assets/delete-icon.png'




const CollateralAdmin = () => {
    const [productModal, setproductModal] = useState(false)
    const [addProductModal, setaddProductModal] = useState(false)
    const [deleteProductModal, setdeleteProductModal] = useState(false)
    const [editProductModal, seteditProductModal] = useState(false)
    const [current, setcurrent] = useState(-1)
    const [products, setproducts] = useState([])
    const [videoDimension, setVideoDimension] = useState({})
    const videoElem = useRef(null)

    useEffect(() => {
        getDashboardData();
    }, [])

    useEffect(() => {
        if (videoElem.current != null) {
            const width = videoElem.current.parentElement.offsetWidth
            const height = videoElem.current.parentElement.offsetHeight - 86

            setVideoDimension({
                width,
                height
            })

        }
    }, [products])
    

    //VIEW PRODUCT MODAL

    const showProductModal = (status, index) => {
        setcurrent(index)
        setproductModal(status)
    }

    const closeProductModal = () => {
        setproductModal(false)
    }

    //CREATE PRODUCT MODAL
    const showAddProductModal = (status) => {
        setaddProductModal(status)
    }
    const closeAddProductModal = () => {
        setaddProductModal(false)
    }

    //DELETE PRODUCT MODAL
    const updateProducts = async (id) => {
        await deleteProductFromServer(id)
    }

    const showDeleteProductModal = (status, index) => {
        setcurrent(index)
        setdeleteProductModal(status)
    }

    const closeDeleteProductModal = () => {
        setdeleteProductModal(false)
    }

    //EDIT PRODUCT MODAL
    const showEditProductModal = (status, index) => {
        setcurrent(index)
        seteditProductModal(status)
    }

    const closeEditProductModal = () => {
        seteditProductModal(false)
    }

    const getDashboardData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(AdminGetCollateral, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success || result.status) {
                    setproducts(result.data)
                }
                else {
                    alert(`${result}`)
                }
            })
            .catch((error) => {
               console.log(error)
            });
    }

    const deleteProductFromServer = async (id) => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(AdminDeleteCollateral(id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT : ", result)
                if (result) {
                    setproducts(products.filter(item => item.id !== id))
                    configuredToastSuccess("Product Removed Successfully", "!!")
                }
                else {
                    configuredToastSuccess("Request Failed", "!!")
                }
            })
            .catch((error) => {
                // console.log(error)
            });
    }

    console.log(products);



    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true}/>
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav active="nav_item_4" />
                    </div>
                    <div className="border-4 border-black rounded-md w-5/6  overflow-y-auto overflow-x-hidden h-max adminMainContainer">
                        <div className="flex justify-between mt-16 items-center">
                            <div className=" ml-12 xs:mx-0">
                                <h1 className="adminHeaderH1"> Collaterals </h1>
                            </div>
                            <div className="mr-12">
                                {/* <Link to="/CollDetails"> */}
                                    <button 
                                        className="bg-black hover:bg-black-700 text-white text-lg  xs:text-xs xs:px-3 xs:py-2 font-bold py-4 px-10 rounded-md ml-4 xs:mt-4"
                                        onClick={() => showAddProductModal(true)}
                                    >
                                        Upload Collateral
                                    </button>
                                {/* </Link> */}
                            </div>
                        </div>

                        <div className="grid grid-cols-4 xl:grid-cols-3 lg-grid-cols-2 gap-16 px-16 py-8">
                                {    
                                    products.map((item, index) => {
                                        return (
                                            <div className=" 2xl:my-8" key={index} >
                                                {
                                                    (item.extension === 'Video')
                                                        ?   <video
                                                                ref={videoElem}
                                                                src={item.asset}
                                                                controls={true}
                                                                style={{
                                                                    width: videoDimension.width || 0,
                                                                    height: videoDimension.height || 0
                                                                }}
                                                            />
                                                        : (item.extension === 'Image')
                                                            ?   <div
                                                                    className="w-64 h-64 border-black border-2 flex justify-end"
                                                                    style={{ backgroundImage: `url(${item.asset}) `, backgroundSize: 'cover',backgroundPosition:'center center no-repeat' }}
                                                                >
                                                                    <img className="2xl:w-12 w-10 self-end  mt-44" src={pin} alt="pin" />
                                                                </div>
                                                            :   
                                                                <a href={item.asset} target="_blank">
                                                                    <div
                                                                        className="w-64 h-64 border-black border-2 flex justify-end"
                                                                        style={{ backgroundImage: `url('../../../pdf.png')`, backgroundSize: 'cover',backgroundPosition:'center center no-repeat' }}
                                                                    >
                                                                            <img className="2xl:w-12 w-10 self-end  mt-44" src={pin} alt="pin" />
                                                                    </div>
                                                                </a>
                                                }

                                                <div>
                                                    <h1 className="text-xl xs:text-sm font-bold">
                                                        { item.name }
                                                    </h1>
                                                    <div className="flex justify-between items-center">
                                                        <h1 className="text-lg xs:text-sm font-bold">
                                                            { item.extension }
                                                        </h1>
                                                        <div className="flex justify-between items-center">
                                                            <Link className="mr-4">
                                                                <img
                                                                    className="w-8 h-8"
                                                                    src={EditIcon}
                                                                    alt="Edit"
                                                                    onClick={() => showEditProductModal(true, index)}
                                                                />
                                                            </Link>
                                                            <Link>
                                                                <img
                                                                    className="w-8 h-8"
                                                                    src={DeleteIcon}
                                                                    alt="Delete"
                                                                    onClick={() => showDeleteProductModal(true, item.id)}
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                        
                                        )
                                    })
                                }
                        </div>

                    </div>
                </div>
                <AdminProductDetailModal
                    show={productModal}
                    onCancel={closeProductModal}
                    data={products[current]}
                />
                <AdminAddProductModal
                    show={addProductModal}
                    onCancel={closeAddProductModal}
                    getDashboardData={getDashboardData}
                />
                <AdminProductDeleteModal
                    show={deleteProductModal}
                    onCancel={closeDeleteProductModal}
                    id={current}
                    onConfirm={updateProducts}
                />
                <AdminEditProductModal
                    show={editProductModal}
                    onCancel={closeEditProductModal}
                    getDashboardData={getDashboardData}
                    data={products[current]}
                />

            </div>
            : <Redirect to="/" />
    )
}

export default CollateralAdmin
 
