import React, { useState, useEffect, useRef } from 'react'
import tick from '../../assets/Group 25.svg'
import EditIcon from './../../assets/edit-icon.png'
import DeleteIcon from './../../assets/delete-icon.png'
import { Redirect, useHistory } from 'react-router-dom'
import AdminNav from '../AdminNav/AdminNav'
import Navbar from '../Navbar/Navbar'
import './../AdminNav/admincommon.css'
import { all_banner, add_banner, DeleteBanner } from '../API/urls'
import { Table } from 'reactstrap'
import { configuredToastSuccess } from "../toast/toast";




const Banners = () => {
    const [dbData, setDashboardData] = useState([]);
    const [banner, setBanner] = useState({})
    const [isSubmitDisable, setIsSubmitDisable] = useState(true)
    let history = useHistory()
    const fileInput = useRef(null);
    

    useEffect(() => {
        getDashboardData();
    }, [])


    const uploadBanner = e => {
        const _fileObj = e.target.files[0]
        const reader = new FileReader();
        reader.readAsDataURL(_fileObj);

        reader.onload = function (e) {
            const image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                const height = this.height;
                const width = this.width;
                const _aspectRatio = (width === 1200) & (height === 400)

                if (_aspectRatio) {
                    setBanner(_fileObj)
                    setIsSubmitDisable(false)
                    return true
                }
                else {
                    setBanner({})
                    setIsSubmitDisable(true)
                    return false
                }
            };
        };
    }


    const getDashboardData = async () => {
        const token = localStorage.getItem('token')
        const requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };

        await fetch(all_banner, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {
                if (result.success || result.status) {
                    setDashboardData(result.data)
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
            });
    }


    const deleteBanner = async (id) => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(DeleteBanner(id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result) {
                    configuredToastSuccess("Banner Removed Successfully", "!!")
                    getDashboardData()
                }
                else {
                    configuredToastSuccess("Request Failed", "!!")
                }
            })
            .catch((error) => {
                // console.log(error)
            });
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsSubmitDisable(true)
        const token = localStorage.getItem('token');
        const formdata = new FormData();
        
        formdata.append('asset', banner);
        
        const requestOptions = {
            method: 'POST',
            body: formdata,
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(add_banner, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT :", result)
                if (result.status)
                    configuredToastSuccess(result.data, "!!")
                    setBanner({})
                    fileInput.current.value = ''
                    setIsSubmitDisable(true)
                    getDashboardData()
            })
            .catch((error) => {
                console.log("catch", error)
            });
    }


    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true} />
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav active="nav_item_3" />
                    </div>
                    <div className="border-4 border-black rounded-md w-5/6 overflow-y-auto overflow-x-hidden h-max adminMainContainer">
                        <div className="py-4">
                            <div className="flex justify-between  py-6 px-8 ">

                                <h1 className="adminHeaderH1 pt-0">Banners</h1>
                                <div className="flex justify-end items-center">
                                    <label htmlFor="search-form relative" style={{position: 'relative'}}>
                                        <input
                                            ref={fileInput}
                                            type="file"
                                            name="banner"
                                            className="search-input"
                                            onChange={uploadBanner}
                                            accept="image/*"
                                            // value={banner.name || ''}
                                        />

                                        <span className="absolute top-full left-0 w-full">
                                            file size should be in 1200x400
                                        </span>
                                    </label>
                                    <button
                                        className="bg-black hover:bg-black-700 text-white text-l xs:text-xs font-bold btn-1 file"
                                        onClick={handleSubmit}
                                        disabled={isSubmitDisable}
                                    >
                                       Submit
                                    </button>

                                </div>
                            </div>
                            <div className="mx-8 my-4">
                                <Table borderless responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <h1 className="2xl:text-2xl text-md font-bold px-2">
                                                    S.No
                                                </h1>
                                            </th>

                                            <th>
                                                <h1 className="2xl:text-2xl text-md font-bold px-2">
                                                    Banners
                                                </h1>
                                            </th>

                                            <th>
                                                <h1 className="2xl:text-2xl text-md font-bold px-2">
                                                    Delete
                                                </h1>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dbData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <h1 className="2xl:text-2xl text-md font-bold px-2">
                                                            { ++index }
                                                        </h1>
                                                    </td>

                                                    <td>
                                                        <div className="border-2 border-gray-400 rounded-md w-2/4">
                                                            <img src={item.asset} alt="img1" style={{ maxWidth: '100%', height: 'auto' }} />
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <img
                                                            className="w-12 h-12 mx-auto cursor-pointer"
                                                            src={DeleteIcon}
                                                            alt="Delete"
                                                            onClick={() => deleteBanner(item.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            : <Redirect to="/" />
    )
}

export default Banners
