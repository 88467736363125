import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
// import proposal from '../../assets/Proposals created (graph).svg'
// import Down from '../../assets/Group 46.svg'
// import Signups from '../../assets/Number of signups.svg'
import select from '../../assets/Support Button (4).svg'
// import date from '../../assets/Support Button (5).svg'
import active from '../../assets/Support Button (7).svg'
import { Link, Redirect } from 'react-router-dom'
import AdminNav from '../AdminNav/AdminNav'
import './../AdminNav/admincommon.css'
import { adminData } from '../API/urls'
import { Bar } from 'react-chartjs-2'




const AdminDash = () => {
    const [modal, setModal] = useState(false)
    const toggleModal = () => {
        setModal(!modal)
    }

    const [proposalGraph, setProposalGraph] = useState([]);
    const [signupGraph, setSignupGraph] = useState([]);
    const [productList, setProductList] = useState([]);
    const [dealerList, setDealerList] = useState([])

    const getData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json", "Authorization": `Bearer ${token}`
            },
        };

        await fetch(adminData, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                return response.json()
            })
            .then((result) => {
                if (result.success || result.status) {
                    setProductList(result.data.Product);
                    setDealerList(result.data.active_dealer);
                    setProposalGraph(Object.values(result.data.BOQ_Graph));
                    setSignupGraph(Object.values(result.data.User_SignUp));
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
            });
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true} />
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav modal={modal} toggleModal={toggleModal} active="nav_item_1" />
                    </div>

                    <div className="border-black border-4 max-h-screen col-span-2 w-5/6 overflow-y-auto rounded-md adminMainContainer">
                        <div className="grid grid-cols-2 2xl:flex xl:flex md:flex lg:flex gap-5 justify-center">
                            <div className="border-gray-300 border-2 col-span-1 m-4 rounded-md w-5/6">
                                {
                                    proposalGraph &&
                                    <Bar
                                        data={{
                                            // Name of the variables on x-axies for each bar
                                            labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                                            datasets: [
                                                {
                                                    // Label for bars
                                                    label: "proposals",
                                                    // Data or value of your each variable
                                                    data: proposalGraph,
                                                },
                                            ],
                                        }}
                                        // Height of graph
                                        height={100}
                                        options={{
                                            maintainAspectRatio: true,
                                        }}
                                    />
                                }
                                {/* <div className="flex justify-end mr-2 mt-5">
                                    <img src={Down} alt="down" />
                                </div> */}

                            </div>
                            <div className="border-gray-300 border-2  col-span-2 m-4 rounded-md w-5/6">
                                {
                                    signupGraph &&
                                    <Bar
                                        data={{
                                            // Name of the variables on x-axies for each bar
                                            labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                                            datasets: [
                                                {
                                                    // Label for bars
                                                    label: "signups",
                                                    // Data or value of your each variable
                                                    data: signupGraph,
                                                },
                                            ],
                                        }}
                                        // Height of graph
                                        height={100}
                                        options={{
                                            maintainAspectRatio: true,
                                        }}
                                    />
                                }
                                {/* <div className="flex justify-end mr-2 mt-6">
                                    <img src={Down} alt="down" />
                                </div> */}

                            </div>
                        </div>
                        <div className="grid grid-cols-2 2xl:flex xl:flex lg:flex md:flex 2xl:justify-center gap-8 mx-4 h-2/3">
                            <div className="flex flex-row col-span-2 w-full h-full">
                                <div className="w-full">
                                    <div className="flex flex-row justify-between pb-4">
                                        <img src={select} alt="select" />
                                    </div>

                                    <div className="grid grid-cols-2 2xl:flex xl:flex lg:flex md:flex justify-around overflow-y-auto h-5/6">
                                        <div className="col-span-1">
                                            {
                                                productList.length > 0 && productList.slice(0, 5).map((product, id) => (
                                                    <div className="flex flex-row" key={id}>
                                                        <div className="border-gray-300 border-2 my-2" ><img className="grid-image" src={product.image} alt="img1" /></div>
                                                        <div className="flex flex-col">
                                                            <h1 className="text-sm px-2" style={{ fontWeight: 600 }}>{product.product_name}</h1>
                                                            <h1 className="text-sm px-2" style={{ fontWeight: 600 }}>{product.price}</h1>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="col-span-2 ">
                                            {productList.length > 0 && productList.slice(5, 10).map((product, id) => (
                                                <div className="flex flex-row" key={id}>
                                                    <div className="border-gray-300 border-2 my-2" ><img className="grid-image" src={product.image} alt="img1" /></div>
                                                    <div className="flex flex-col">
                                                        <h1 className="text-sm px-2 " style={{ fontWeight: 600 }}>{product.product_name}</h1>
                                                        <h1 className="text-sm px-2" style={{ fontWeight: 600 }}>{product.price}</h1>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 w-full h-full">
                                <div className="flex flex-row h-full">
                                    <div className="w-full">
                                        <div className="flex flex-row justify-between pb-4">
                                            <img src={active} alt="active" />
                                        </div>
                                        <div className="flex flex-row justify-around overflow-y-auto h-5/6">
                                            <div>
                                                {
                                                    dealerList.length > 0 && dealerList.slice(0, 5).map((dealer, id) => (
                                                        <div className="flex flex-row border-gray-300 border-2 mb-2" key={id}>
                                                            {/* <div className="border-gray-300 border-2 my-2 w-20 h-20 rounded-full"></div> */}
                                                            <div className="flex flex-col">
                                                                <h1 className="text-sm px-2 pt-2" style={{ fontWeight: 600 }}>{id + 1}.) {dealer.name}</h1>
                                                                <p className="text-xs px-2 pb-2" style={{ fontWeight: 600 }}>{dealer.city}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            <div>
                                                {
                                                    dealerList.length > 0 && dealerList.slice(5, 10).map((dealer, id) => (
                                                        <div className="flex flex-row border-gray-300 border-2 mb-2">
                                                            {/* <div className="border-gray-300 border-2 my-2 w-20 h-20 rounded-full"></div> */}
                                                            <div className="flex flex-col">
                                                                <h1 className="text-sm px-2 pt-2" style={{ fontWeight: 600 }}>{id + 6}.) {dealer.name}</h1>
                                                                <p className="text-xs px-2 pb-2" style={{ fontWeight: 600 }}>{dealer.city}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            : <Redirect to='/' />
    )
}

export default AdminDash
