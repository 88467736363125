import {
    Col,
    Row,
    Media,
} from "reactstrap";
import React, { Component } from "react";
import Carousel from './Carousel';

class AdminProductDetailModal extends Component {
    EachItem = (heading, data) => {
        return (
            <div style={{ marginTop: 30 }}>
                <Row>
                    <Col>
                        <h5 style={{ fontWeight: 600 }}>{heading} : </h5>
                    </Col>
                    <hr style={{ width: '70%', textAlign: 'left', marginLeft: 0, marginTop: 0 }}/>
                </Row>
                
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <p style={{ textTransform: 'capitalize' }}>{data}</p>
                    </Col>
                </Row>
                {/* <hr style={{ width: '80%', textAlign: 'left', marginLeft: 0, marginTop: 0 }} /> */}
            </div>
        )
    }

    render() {
        const { data } = this.props;
        if (data == undefined || this.props.show===false) return <></>
        else
            return (
                <>
                    <div
                        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {data.modelName}
                                    </h3>
                                    <button
                                        className="ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => this.props.onCancel()}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <Row>
                                        <Col md="2" lg="2" />
                                        <Col md="8" lg="8">
                                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                                {/* <Media src={`${data.image}`} className="img-fluid" /> */}
                                                <Carousel image={data.image} image1={data.image1} image2={data.image2} image3={data.image3} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <div style={{ padding: 40 }} className="grid grid-cols-2">
                                        {data.SKU_code && this.EachItem('SKU CODE', data.SKU_code)}
                                        {data.type && this.EachItem('TYPE', data.type)}
                                        {data.Price && this.EachItem('PRICE', data.Price)}
                                        {data.Runnning_Length && this.EachItem('RUNNING LENGTH', data.Runnning_Length)}
                                        {data.opening && this.EachItem('OPENING', data.opening)}
                                        {data.Finish && this.EachItem('FINISH', data.Finish)}
                                        {data.config && this.EachItem('CONFIG', data.config)}
                                        {data.height && this.EachItem('HEIGHT', data.height)}
                                        {data.glass_thickness && this.EachItem('GLASS THICKNESS', data.glass_thickness)}
                                        {data.pattern && this.EachItem('PATTERN', data.pattern)}
                                        {data.glassType && this.EachItem('GLASS TYPE', data.glassType)}
                                        {data.accessories && this.EachItem('ACCESSORIES', data.accessories)}
                                    </div>

                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => this.props.onCancel()}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            );
    }
}

export default AdminProductDetailModal;
