import React, { useState, useEffect } from 'react'
import tick from '../../assets/Group 25.svg'
import EditIcon from './../../assets/edit-icon.png'
import DeleteIcon from './../../assets/delete-icon.png'
import { Link, Redirect, useHistory } from 'react-router-dom'
import AdminNav from '../AdminNav/AdminNav'
import Navbar from '../Navbar/Navbar'
import './../AdminNav/admincommon.css'
import { adminDeleteDealer, getDealers } from '../API/urls'
import AdminAddDealerModal from './AdminAddDealerModal'
import AdminDealerDeleteModal from './AdminDealerDelete'
import AdminEditDealerModal from './AdminEditDealerModal'
import { configuredToastSuccess } from '../toast/toast'
import { Table } from 'reactstrap'

const Dealers = () => {
    const [addDealerModal, setaddDealerModal] = useState(false)
    const [deleteDealerModal, setDeleteDealerModal] = useState(false)
    const [editDealerModal, seteditDealerModal] = useState(false)
    const [current, setcurrent] = useState(null)
    const [dealers, setDealers] = useState([])
    const [query, setQuery] = useState("");
    const [dbData, setDashboardData] = useState(null);

    let history = useHistory()

    const updateInput = async (query) => {
        if (dbData) {
            const filtered = dbData.filter(item => {
                return item['dealer_name'].toLowerCase().includes(query.toLowerCase()) || JSON.stringify(item['dealer_code']).includes(query) || JSON.stringify(item['city']).includes(query) || JSON.stringify(item['state']).includes(query)
            })
            await setQuery(query);
            await setDealers(filtered);
        }
    }


    //CREATE DEALER MODAL
    const showAddDealerModal = (status) => {
        setaddDealerModal(status)
    }
    const closeAddDealerModal = () => {
        setaddDealerModal(false)
    }

    //DELETE DEALER MODAL
    const updateDealers = async (id) => {
        await deleteDealer(id)
    }

    const showDeleteDealerModal = (status, index) => {
        setcurrent(index)
        setDeleteDealerModal(status)
    }

    const closeDeleteDealerModal = () => {
        setDeleteDealerModal(false)
    }

    //EDIT PRODUCT MODAL
    const showEditDealerModal = (status, index) => {
        setcurrent(index)
        seteditDealerModal(status)
    }

    const closeEditDealerModal = () => {
        seteditDealerModal(false)
    }

    const getDashboardData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };

        await fetch(getDealers, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {

                if (result.success || result.status) {
                    setDealers(result.data);
                    setDashboardData(result.data);
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
            });
    }

    const deleteDealer = async (id) => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(adminDeleteDealer(id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT : ", result)
                if (result) {
                    setDealers(dealers.filter(item => item.id !== id))
                    configuredToastSuccess("Dealer Removed Successfully", "!!")
                }
                else {
                    configuredToastSuccess("Failed to Delete", "!!")
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {
        getDashboardData();
    }, [addDealerModal, deleteDealerModal, editDealerModal])

    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true} />
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav active="nav_item_3" />
                    </div>
                    <div className="border-4 border-black rounded-md w-5/6 overflow-y-auto overflow-x-hidden h-max adminMainContainer">
                        <div className="py-4">
                            <div className="flex justify-between items-center py-6 px-8">

                                <h1 className="adminHeaderH1">Dealers</h1>
                                <div className="flex justify-end items-center">
                                    <label htmlFor="search-form">
                                        <input
                                            type="search"
                                            name="search-form"
                                            id="search-form"
                                            className="search-input"
                                            placeholder="Search for..."
                                            value={query}
                                            onChange={(e) => updateInput(e.target.value)}
                                        />
                                    </label>
                                    <button
                                        style={{ borderRadius: 4 }}
                                        className="bg-black hover:bg-black-700 text-white text-l xs:text-xs font-bold py-4 px-6 xs:px-4"
                                        onClick={() => showAddDealerModal(true)}
                                    >
                                        Add New +
                                    </button>
                                </div>
                            </div>
                            <div className="mx-8 my-4">
                                <Table borderless responsive>
                                    <thead>
                                        <tr>
                                            <th>Dealer Code</th>
                                            <th>Dealer Name</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dealers.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.dealer_code}</td>
                                                        <td>{item.dealer_name}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        <td> <img className="h-10 w-10 object-scale-down" src={EditIcon} alt="Edit" onClick={() => showEditDealerModal(true, index)} /></td>
                                                        <td><img onClick={() => { showDeleteDealerModal(true, item.id) }} className="h-10 w-10 object-scale-down" src={DeleteIcon} alt="Delete" /></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    </div>
                </div>
                <AdminAddDealerModal
                    show={addDealerModal}
                    onCancel={closeAddDealerModal}
                />
                <AdminDealerDeleteModal
                    show={deleteDealerModal}
                    onCancel={closeDeleteDealerModal}
                    id={current}
                    onConfirm={updateDealers}
                />
                <AdminEditDealerModal
                    show={editDealerModal}
                    onCancel={closeEditDealerModal}
                    data={dealers[current]}
                />

            </div>
            : <Redirect to="/" />
    )
}

export default Dealers
