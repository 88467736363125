import React, { useState, useEffect, useContext } from 'react'
import './style.css'
import { Redirect, useHistory } from 'react-router-dom'
import { ProductContext } from '../../helpers/products/productContext'

const Filter = ({ data, filter, setFields, dataFiltered, setDataFiltered, filterFields, setFilterFields, setShow, show }) => { 
    const productContext = useContext(ProductContext)
    const reset = async () => {
        setFields({})
        setDataFiltered(data)
        setShow([])
        await productContext.handleFilter(data)
        setFilterFields(productContext.filterFields)
    }

    const normalFilters = [
        {
            id: "type",
            name: "Type of enclosure"
        },
        {
            id: "config",
            name: "Shape"
        },
        {
            id: "Runnning_Length",
            name: "Running Length"
        },
//         {
//             id: "opening",
//             name: "Opening"
//         },
        {
            id: "Finish",
            name: "Enclosure Finish"
        }
    ]

    const additionalFilters = [
        {
            id: "height",
            name: "Height"
        },
        {
            id: "glass_thickness",
            name: "Glass Thickness"
        },
        {
            id: "pattern",
            name: "Pattern"
        },
//         {
//             id: "glassType",
//             name: "Glass Type"
//         },
        {
            id: "accessories",
            name: "Accessories"
        }
    ]

    let history = useHistory()
    const handleFilter = () => {
        localStorage.setItem('alreadyFiltered', true)
        history.push('/results')
    }

    return (
        localStorage.getItem('isLoggedIn')
            ? 
            <div className="flex justify-center">
                <div className="filter-container flex flex-col justify-center m-4 xs:m-0 xs:max-h-screen sm:max-h-screen sm:m-0 md:m-0  p-8 md:p-8 overflow-y-auto w-max  h-full md:max-h-screen " style={{ border: '3px black solid' }}>
                    <div className=" flex flex-row justify-between items-center">
                        <h1 className="text-2xl font-bold">Filters</h1>
                        <button className="border-black border-1 py-1 ml-5 px-4 sm:px-10 focus:bg-white focus:text-black xs:px-6 bg-black text-white font-bold text-sm xs:text-xs rounded-md transition ease-in-out duration-300 hover:bg-white hover:text-black"
                            onClick={() => reset()}>
                            Reset
                        </button>
                    </div>
                    <div className=" md:m-6 overflow-y-auto">
                        {
                            normalFilters.map((item, index) => {
                                return (
                                    filterFields[item.id].length > 0 &&
                                    <div className="my-4 md:my-0 flex flex-col mx-8 lg:mx-4 sm:overflow-y-auto md:overflow-y-auto xs:overflow-y-auto " key={index}>
                                        <h1 style={{ marginBottom: 10 }} className="text-xl lg:text-sm font-bold">{item.name}</h1>
                                        <TypeFunc show={show} setShow={setShow} filterFields={filterFields} setFilterFields={setFilterFields} data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} fields={filter} setfields={setFields} category={item.id} filter={filter[item.id] || []} />
                                    </div>
                                )
                            })
                        }
                        <br />

                        <div className="mx-8 md:my-4">
                            <h1 className="text-2xl font-bold">Additional</h1>
                        </div>
                        {
                            additionalFilters.map((item, index) => { 
                                return (
                                    filterFields[item.id].length > 0 &&
                                    <div className="my-4 md:my-0 flex flex-col mx-8 lg:mx-4" key={index + 10}>
                                        <h1 style={{ marginBottom: 10 }} className="text-xl lg:text-sm font-bold">{item.name}</h1>
                                        <TypeFunc show={show} setShow={setShow} filterFields={filterFields} setFilterFields={setFilterFields} data={data} dataFiltered={dataFiltered} setDataFiltered={setDataFiltered} fields={filter} setfields={setFields} category={item.id} filter={filter[item.id] || []} />
                                    </div>
                                )
                            })
                        }

                        < div className="button my-5">
                            <button className="border-black border-4 px-5 mx-10 sm:px-10 focus:bg-white focus:text-black xs:px-6 xs:py-2 py-2 bg-black text-white font-bold text-sm xs:text-xs rounded-md transition ease-in-out duration-300 hover:bg-white hover:text-black" onClick={handleFilter}>Filter & close</button>
                        </div>
                    </div>

                </div>
            </div >
            : <Redirect to="/" />
    )
}


function TypeFunc({ data, fields, setfields, filter, category, setDataFiltered, dataFiltered, filterFields, setFilterFields, show, setShow }) {
    const [tempFilter, setTempFilter] = useState(filter)

    let filterDic = {
        "height": 4,
        "glass_thickness": 3,
        "pattern": 1,
        "glassType": 2,
        "accessories": 5
    }

    let standardValue = {
        "glass_thickness": "8mm",
        "pattern": "No Pattern",
        "height": "2000mm"
    }

    const sortFunction = async (fields) => {

        // console.log(" field and data ==  ", fields, data)
        let tempData = data
        for (const [key, value] of Object.entries(fields)) {
            if (value.length === 0) {
                continue
            }
            let arr = []
            let extradata = []
            console.log('Data BEFORE : ', tempData)
            tempData.forEach((ele) => {
                let f = 0

                if ((ele[key] === null || ele[key] === "")) {
                    extradata.push(ele)
                    f = 1
                }
                else if (f === 0 && ele[key] && (key in standardValue && ele[key].includes(standardValue[key]))) {
                    extradata.push(ele)
                    f = 1
                }
                else {
                    value.forEach((str) => {
                        if (f === 0 && ele[key] && (ele[key].includes(str))) {
                            arr.unshift(ele)
                            f = 1
                        }
                    })
                }
            })
            const _newData = extradata && extradata.filter((data) =>{
                return data.type !== "" && data.type !== null
            })
            tempData = [...arr, ..._newData];
        }
        let temp_filter = filterFields

        console.log('Data field 01 : ', tempData)
        console.log('Data field 10 : ', fields)
        console.log("Data field 11 : ", temp_filter)
        console.log("console show on close == ", show)

        const filterKeys = ['type', 'config', 'Runnning_Length', 'opening', 'Finish']
        const additionalKeys = ['height', 'pattern', 'glassType', 'glass_thickness', ]

        filterKeys.map(item => {
            if (!(item in fields)) {
                let a = new Set()
                tempData.forEach(ele => {
                    // if (ele[item] && show.includes(ele['status_type'])) {

                    //     let arr = ele[item].split(",")
                    //     arr.forEach((ele) => {
                    //         a.add(ele.trim())
                    //     })

                    // }
                    if (ele[item]) {

                        let arr = ele[item].split(",")
                        arr.forEach((ele) => {
                            a.add(ele.trim())
                        })

                    }
                })
                temp_filter[item] = [...a]
            }
        })

        additionalKeys.map(item => {
            if (!(item in fields)) {
                let a = new Set()
                tempData.forEach(ele => {
                    if (ele[item]) {

                        let arr = ele[item].split(",")
                        arr.forEach((ele) => {
                            a.add(ele.trim())
                        })

                    }
                })
                temp_filter[item] = [...a]
            }
        })


        setFilterFields(temp_filter)
       
        setDataFiltered(tempData)
        
        // console.log("temp filter data : ", temp_filter)
    }

    useEffect(() => {
        setTempFilter(filter)
    }, [filter])

    useEffect(() => {
        sortFunction(fields, false)
    }, [])


    const handleClose = async (item) => {
        // console.log("handle close filter: ",item)
        let arr = tempFilter.filter((ele) => ele !== item)
        let temp = fields
        if (arr.length === 0) {
            delete temp[category];
        }
        else {
            temp[category] = arr;
        }
        await setfields(temp)
        await setTempFilter([...arr])
        let tempShow = show
        if (arr.length === 0 && (category in filterDic)) {
            if (show.includes(filterDic[category])) {
                tempShow = show.filter((ele) => ele !== filterDic[category])
            }
        }
        show = tempShow
        await setShow(show)
        // console.log("FILTER FIELDS : ", filterFields)
        await sortFunction(temp)
    }

    const addToFilter = async (item) => {
        // console.log("handle add to filter: ",item)
        tempFilter.push(item)
        let temp = fields
        temp[category] = tempFilter
        await setfields(temp)
        await setTempFilter([...tempFilter])
        if (tempFilter.length > 0 && (category in filterDic)) {
            if (!show.includes(filterDic[category])) {
                show.push(filterDic[category])
            }
        }
        await setShow(show)
        await sortFunction(temp)
    }

    let dic = {
        "CP": "CP-Chrome Plated",
        "AF": "AF-French Gold",
        "RGD": "RGD-Rose Gold",
        "BV": "BV-Brushed Bronze",
        "BN": "BN-Brushed Nichel",
    }

    let listItem = []
    listItem = category === 'Finish'
        ? filterFields[category].map(item =>
            (tempFilter.includes(item))
                ? <div className="flex justify-between">
                    <p style={{ color: "#000", fontWeight: 'bold' }}>{!(item in dic) ? item : dic[item]}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 25 25" stroke="currentcolor" onClick={() => handleClose(item)}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                : <div className="flex justify-between">
                    <button style={{ outline: 'none' }} onClick={() => addToFilter(item)}>
                        <p>{!(item in dic) ? item : dic[item]}</p>
                    </button>
                </div>
        )
        : filterFields[category].map(item =>
            (tempFilter.includes(item))
                ? <div className="flex justify-between">
                    <p style={{ color: "#000", fontWeight: 'bold' }}>{item}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 cursor-pointer" fill="none" viewBox="0 0 25 25" stroke="currentcolor" onClick={() => handleClose(item)}>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                : <div className="flex justify-between">
                    <button style={{ outline: 'none' }} onClick={() => addToFilter(item)}>
                        <p>{item}</p>
                    </button>
                </div>
        )
    return (listItem);
}

export default Filter
