import React from 'react'
import { Link, useLocation } from 'react-router-dom'


const Navigation = () => {
    const location = useLocation();
    return (
        <div className="navParentAdmin">
            <Link to="/admindash">
                <p
                    className={location.pathname == "/admindash" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_1"
                >
                    Dashboard
                </p>
            </Link>
            <Link to="/BOQ-admin">
                <p
                    className={location.pathname == "/BOQ-admin" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_2"
                >
                    BOQ
                </p>
            </Link>
            <Link to="/banners">
                <p
                    className={location.pathname == "/banners" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_2"
                >
                    Banner
                </p>
            </Link>
            <Link to="/Products">
                <p
                    className={location.pathname == "/Products" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_3"
                >
                    Products
                </p>
            </Link>
            <Link to="/Dealers">
                <p
                    className={location.pathname == "/Dealers" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_4"
                >
                    Dealers
                </p>
            </Link>
            <Link to="/Users">
                <p
                    className={location.pathname == "/Users" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_4"
                >
                    Users
                </p>
            </Link>
            <Link to="/CollateralAdmin">
                <p
                    className={location.pathname == "/CollateralAdmin" ? 'navi-n navi-active' : 'navi-n'}
                    id="nav_item_5"
                >
                    Collaterals
                </p>
            </Link>
            <Link to={{pathname:"https://kohler.servitiumcrm.com/kohler/showerEnclosure/showerEnclosure.jsp"}} target="_blank">
                <p className='navi-n' id="nav_item_5">
                    Track Here
                </p>
            </Link>
        </div>
    )
}

export default Navigation
