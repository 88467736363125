import React, { useState, useEffect } from "react";
import {updateUser } from "../API/urls";
import { configuredToastSuccess } from "../toast/toast";
import Autocomplete from "../register/Autocomplete";

const AdminEditUserModal = ({ onCancel, show, data }) => {
    const initialState = {
        name: "",
        mobile_no: "",
        account_type: "",
    }
    const [showFlag, setshowFlag] = useState(false);
    const [formInfo, setformInfo] = useState(initialState);
    const [selectedDealer, setSelectedDealer] = useState('');
    const [dealerId, setDealerId] = useState('');


    
    useEffect(() => {
        if (data) {
            console.log(data,"Inside if")
            let sendToServer = {
                name: data.name,
                mobile_no: data.mobile_no,
                account_type: data.account_status
            }
            console.log(sendToServer,"sendtoSErver")
            setformInfo({ ...sendToServer })
            
            if(data.account_status==="Dealer"){
                console.log("Here");
                setDealerId(data.id);
                setSelectedDealer(data.dealer_name);
            }
        }
    }, [data,show])

    let map = {
        "Dealer": 2,
        "Customer": 3
    }



    const handleTerminate = () => {
        setformInfo({ ...initialState })
        onCancel()
        setshowFlag(false)
    }

    const handleInputChange = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        setformInfo({ ...formInfo, [key]: val })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addUserToServer()
        if (showFlag) handleTerminate()
    }

    const addUserToServer = async () => {
            let token = localStorage.getItem('token');
            var formdata = new FormData();
            formdata.append("mobile_no", formInfo.mobile_no);
            formdata.append("name", formInfo.name);
            formdata.append("account_type", map[formInfo.account_type]);
    
            if (formInfo.account_type === "Dealer") {
                formdata.append("dealer_id", dealerId);
            }
    
            var requestOptions = {
                method: 'POST',
                body: formdata,
                headers: { "Authorization": `Bearer ${token}` },
            };

            await fetch(updateUser(data.id), requestOptions)
                .then(response => response.json())
                .then((result) => {
                    configuredToastSuccess("Success", "!!")
                })
                .catch((error) => {
                    console.log("catch", error)
                });

    }


    if (show === false) return <></>
    else
        return (
            <>
                <div
                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    EDIT USER
                                </h3>
                                <button
                                    className="ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={handleTerminate}
                                >
                                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        X
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                            <form className="w-full" onSubmit={handleSubmit}>
                                    <div className=" grid grid-cols-2">
                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    NAME
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    type="text"
                                                    placeholder="NAME"
                                                    name="name"
                                                    value={formInfo.name}
                                                    onChange={handleInputChange}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    MOBILE NO
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    type="text"
                                                    placeholder="MOBILE NUMBER"
                                                    name="mobile_no"
                                                    value={formInfo.mobile_no}
                                                    onChange={handleInputChange}
                                                    required
                                                ></input>
                                            </div>
                                        </div>


                                        <div className="flex flex-wrap -mx-3 mb-6">

                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                                    ACCOUNT TYPE
                                                </label>
                                                <select className="appearance-none border block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Dealer/Customer" name="account_type" value={formInfo.account_type} onChange={(event) => setformInfo({ ...formInfo, 'account_type': event.target.value })} required>
                                                    <option>Select</option>
                                                    <option>Dealer</option>
                                                    <option>Customer</option>
                                                </select>
                                            </div>


                                            {
                                                formInfo.account_type === "Dealer" &&
                                                <div className="w-full px-3">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                                        DEALER NAME
                                                    </label>
                                                    <div className="suggestion-div flex flex-col self-center items-center " style={{ padding: 0, }}>
                                                        <Autocomplete
                                                            className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                            placeholder="Dealer/Customer"
                                                            input={selectedDealer}
                                                            setInput={setSelectedDealer}
                                                            dealerId={dealerId}
                                                            setDealerId={setDealerId}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="border-2 border-black rounded-md mr-4 text-white bg-black font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                            onClick={() => setshowFlag(true)}
                                            disabled={formInfo.account_type === "Dealer" && dealerId !== "" && selectedDealer !== "" && formInfo.mobile_no.length === 13 && formInfo.name !== "" ? false :
                                                formInfo.account_type === "Customer" && formInfo.mobile_no.length === 13 && formInfo.name !== "" ? false : true}
                                        >
                                            Save & Close
                                        </button>
                                        <button
                                            className="border-2 border-red-600 rounded-md text-white bg-red-600 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mr-4"
                                            type="button"
                                            onClick={handleTerminate}
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        );
}

export default AdminEditUserModal;
