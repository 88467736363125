import React, { useState, useContext } from 'react'
import Navbar from '../Navbar/Navbar'
import { Link, Redirect, useHistory } from 'react-router-dom'
import './Question3.css'
import { ProductContext } from '../../helpers/products/productContext'
import back from '../../assets/back icon@2x.jpg'
import { Messages } from '../messages'
import { configuredToastSuccess } from '../toast/toast'

const Question3 = ({ data, fields, setFields, show, setShow, filterFields, setFilterFields, imagesObj }) => {
    const productContext = useContext(ProductContext);
    imagesObj = productContext.images;
    const [input, setInput] = useState([])
    const [btn, setbtn] = useState([])

    let history = useHistory();
    const nextFunction = async () => {
        if (input.length <= 0) {
            configuredToastSuccess(Messages.QuestionSelectionError, "!!")
            return
        }
        await setFields({ ...fields, 'height': input })
        if (input.length > 0 && !show.includes(4)) {
            show.push(4)
        }
        await setShow(show)
        await FilterBulk({ ...fields, 'height': input })
        history.push('/question4')
    }

    const FilterBulk = async (fields) => { 
        console.log("question 3")
        let tempData = data
        for (const [key, value] of Object.entries(fields)) {
            if (value.length === 0) {
                continue
            }
            let arr = []
            let extradata = []
            tempData.forEach((ele) => {
                let f = 0
                if (key === "height" || ele[key] === null || ele[key] === "") {
                    extradata.push(ele)
                    f = 1
                }
                else {
                    value.forEach((str) => {
                        if (f === 0 && ele[key] && ele[key].includes(str)) {
                            arr.push(ele)
                            f = 1
                        }
                    })
                }

            })
            
            tempData = [...arr, ...extradata];
            console.log(tempData,"question 3")
        }

        console.log("question 3 data ", tempData)
        let temp_filter = filterFields

        let item = "pattern"
        let a = new Set()
        tempData.forEach(ele => {
            if (ele[item]) {
                let arr = ele[item].split(",")
                arr.forEach((ele) => {
                    a.add(ele.trim())
                })
            }
        })
        temp_filter[item] = [...a]
        console.log(temp_filter[item])
        setFilterFields(temp_filter)
        console.log("question 3 patterns", temp_filter)
    }

    const skipFunction = async () => {
        await FilterBulk(fields)
        history.push('/question4')
    }

    const handleBtn = (selected, BNo) => {
        const index = input.indexOf(selected);
        const bindex = btn.indexOf(BNo);
        // console.log(index, bindex)
        if (index < 0) {
            input.push(selected);
            btn.push(BNo)

        } else {
            input.splice(index, 1);
            btn.splice(bindex, 1)
        }
        setInput([...input]);
        setbtn([...btn]);
    }

    const getStyle = (b) => {
        if (b >= 0) {
            return ({
                borderWidth: 4,
                borderColor: 'black',
                borderRadius: 5,

            })
        }
        else {
            return ({
                borderWidth: 0,
            })
        }
    }


    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="w-full questions">
                <Navbar />
                <div className="ques-container">
                    <div className="flex justify-center my-5 mx-2">
                        <p className="based">What is your enclosure height preference?</p>
                    </div>

                    <div className="overflow-y-auto flex flex-wrap justify-center items-center gap-9" style={{ height: 'calc(55vh)', width: 'calc(100vw * 5 / 6)' }}>
                        {
                            filterFields['height'].length > 0 ? filterFields['height'].map((item, id) => {
                                // console.log("question filters options == ", item)
                                return (

                                    <div key={id} className="flex flex-col justify-center items-center w-1/4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 xs:w-1/3 mb-4 options">
                                        <button active={input.includes(item)} onClick={() => handleBtn(item, id)} className="transition ease-in-out duration-300">
                                            <img src={imagesObj[item]} alt="img1" style={getStyle(btn.indexOf(id))} />
                                        </button>
                                        <span className="text-2xl">{item}</span>
                                    </div>
                                )
                            })
                                : <div onClick={skipFunction}></div>
                        }
                    </div>

                    <div className="flex justify-center mt-5">
                        <button className="bg-white text-black border-black px-12 xs:px-6 xs:py-1 py-3 rounded-md text-xl font-bold cursor-pointer hover:text-white hover:bg-black transition ease-in-out duration-300 next" style={{ border: '3px black solid' }} onClick={nextFunction}>Next</button>
                    </div>
                </div>

                <div className="flex justify-between">
                    <Link to="/question2" style={{ position: 'absolute', bottom: 20, left: 10 }}>
                        <img className="back" src={back} alt="back" />
                    </Link>
                    <button className="bg-white text-black border-black  px-12 xs:px-6 xs:py-1 py-3 rounded-md text-xl font-bold cursor-pointer hover:text-white hover:bg-black transition ease-in-out duration-300 skip" style={{ border: '3px black solid', right: 10, position: 'absolute', bottom: 20 }} onClick={skipFunction}>Skip</button>
                </div>

            </div>
            : <Redirect to='/' />
    )
}

export default Question3
