import { Fragment} from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationIcon } from '@heroicons/react/outline'
import { add_new_BOQ } from '../API/urls'
import { useHistory } from 'react-router-dom'



export default function Modal({ modal, toggleModal }) {

    let history = useHistory();
    const handleNewBOQ = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: { 
"Accept": "application/json",  "Authorization": `Bearer ${token}` },
        };

        await fetch(add_new_BOQ, requestOptions)
            .then(response => {
            console.log("data = ", response.status)
            if(response.status === 401){
                localStorage.clear()
               localStorage.setItem('isLoggedIn', false)
               history.push('/')
            }
            return response.json()
         })         
            .then((result) => {

                if (result.success || result.status) {
                    localStorage.setItem('boq_id', result.data.boq_id)
                    // console.log("form modal new boq", result.data.boq_id)
                    toggleModal();
                    history.push('/results')
                }
                else {
                    // alert(`${result}`)
                    toggleModal();
                }
            })
            .catch((error) => {
console.log("data = ", error.status)
                // console.log(error)
            });

        toggleModal();
    }

    const handleExistingBOQ = () =>{
        // console.log("from modal existing boq")
        toggleModal();
        history.push('/results')
}

    return (
        <Transition.Root show={modal} as={Fragment}>
            <Dialog
                as="div"
                auto-reopen="true"
                className="fixed z-10 inset-0 overflow-y-auto self-center"
                onClose={toggleModal}
            >
                <div className="flex items-center justify-center align-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="flex flex-col w-1/3 lg:w-1/3 md:w-2/3 sm:w-full xs:w-full py-7 px-7 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">

                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            CREATE BOQ
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to continue with ?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row-reverse gap-8 px-4 pt-7 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    style={{ backgroundColor: 'black' }}
                                    onClick={handleNewBOQ}
                                >
                                    Create New BOQ
                                </button>
                                <button
                                    type="button"
                                    className="w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-black-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    style={{ backgroundColor: 'black' }}
                                    onClick={handleExistingBOQ}
                                >
                                    Existing BOQ
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
