import React, {useEffect, useContext} from 'react'
import Navbar from '../Navbar/Navbar'
import Filter from '../Filters/Filter'
import ProductDisplay from '../ProductDisplay/Display'
import './style.css'
import { Redirect } from 'react-router-dom'
import { ProductContext } from '../../helpers/products/productContext'


const Results = ({ fields, setFields, data, setData, dataFiltered, setDataFiltered, setFilterFields, filterFields, show, setShow }) => {
    const productContext = useContext(ProductContext)
    useEffect(() => {
        if (localStorage.getItem("alreadyFiltered")) {
            FilterBulk()
            localStorage.removeItem("alreadyFiltered")
        } else {
            productContext.toggleDataTrigger()
        }

    }, [])    

    let standardValue = {
        "glass_thickness": "8mm",
        "pattern": "No Pattern",
        "height": "2000mm"
    }


    const FilterBulk = async () => { 

        let tempData = data
        for (const [key, value] of Object.entries(fields)) {
            if (value.length === 0) {
                continue
            }
            let arr = []
            let extradata = []
            tempData.forEach((ele) => {
                let f = 0
                if (key in standardValue && ele[key].includes(standardValue[key])){
                    extradata.push(ele)
                    f = 1
                }
                if (key in standardValue){
                    extradata.push(ele)
                    f = 1
                }
                else if (ele[key] === null || ele[key] === "") {
                    extradata.push(ele)
                    f = 1
                }
                value.forEach((str) => {
                    if (f === 0 && ele[key] && ele[key].includes(str)) {
                        arr.push(ele)
                        f = 1
                    }
                })
            })
            tempData = [...arr, ...extradata];
        }
        let temp_filter = filterFields

        const filterKeys = ['type', 'config', 'Runnning_Length', 'opening', 'Finish', 'height', 'pattern', 'glassType', 'glass_thickness','accessories']

        filterKeys.map(item => {
            if (!(item in fields) && show.length === 1) {
                let a = new Set()
                tempData.forEach(ele => {
                    if (ele[item]) {
                        let arr = ele[item].split(",")
                        arr.forEach((ele) => {
                            a.add(ele.trim())
                        })
                    }
                })
                temp_filter[item] = [...a]
            }
        })

        setFilterFields(temp_filter)
        setDataFiltered(tempData)
        // console.log("temp filter data : ", temp_filter)
    }
    
    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-y-auto overflow-x-hidden">
                <div className="navb">
                    <Navbar />
                </div>
                <div className="flex flex-col text-center mt-5">
                    {/* <img className="w-1/2" src={based} alt="based selections" /> */}
                    <p className="based">Based on your selections,</p>
                    <p className="based">here are some recommended products.</p>
                </div><br/>
                <div className="box flex justify-between">
                    <div className="col1 col-span-1">
                        <Filter
                            filter={fields}
                            setFields={setFields}
                            setData={setData}
                            setDataFiltered={setDataFiltered}
                            dataFiltered={dataFiltered}
                            data={data}
                            filterFields={filterFields}
                            setFilterFields={setFilterFields}
                            show={show}
                            setShow={setShow}
                        />
                    </div>
                    <div className="col2 col-span-2">
                        <ProductDisplay
                            fields={fields}
                            setFields={setFields}
                            data={data}
                            setData={setData}
                            setDataFiltered={setDataFiltered}
                            dataFiltered={dataFiltered}
                            show={show}
                            setShow={setShow}
                        />
                    </div>
                    <div>
                    </div>

                </div>
            </div>
            : <Redirect to="/" />

    )
}

export default Results