import React from 'react'
import details from '../../assets/Product Name.svg'
import photo2 from '../../assets/Torsion_Sliding_1D2P_1700_asm_CP (1)/Torsion_Sliding_1D2P_1700_asm_CP.png'
import photo1 from '../../assets/Product Details.svg'
import add from '../../assets/Group 42.svg'
import { Redirect } from 'react-router-dom'


const ProductDetails = () => {
    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-y-hidden">
                <div className="flex justify-center h-max w-full mx-auto my-8">
                    <div className="border-black border-4 grid-cols-2 flex justify-center w-max md:w-3/4 sm:w-3/4 xs:w-full h-full rounded-md  md:mx-12 xs:mx-8 xs:mt-24">
                        <div className="col-span-1 w-1/2 ">
                            <div className="ml-8 md:mx-4 sm:mx-4 my-8">
                                <img className="" src={details} alt="product-details" />
                            </div>
                            <div className="ml-10 md:mx-4 sm:mx-4">
                                <img className="h-64 w-52 xs:w-28 xs:h-32" src={photo2} alt="photo2" />
                            </div>
                        </div>
                        <div className="col-span-2 w-1/2">
                            <div className=" mt-28 xs:mt-0 xs:mx-2">
                                <img className="xs:w-64 xs:h-24 " src={photo1} alt="photo1" />
                                <div className=" w-full ">
                                    <p className="text-xl md:text-lg sm:text-sm xs:text-xs">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing <br /> tempor incididunt ut labore et dolore</p>
                                </div>
                            </div>
                            <div className=" flex flex-row justify-between px-4 xs:w-full">
                                <div className="mt-16">
                                    <h1 className="2xl:text-2xl md:text-lg text-sm" style={{ fontWeight: 600 }}>SKU CODE</h1>
                                </div>
                                <div className="mt-16">
                                    <h1 className="2xl:text-2xl md:text-lg text-sm" style={{ fontWeight: 600 }}>Price</h1>
                                </div>
                            </div>
                            <div className="mt-12 flex justify-end ">
                                <img className="md:w-32 md:h-16 xs:w-16 xs:h-12" src={add} alt="add"></img>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            : <Redirect to="/" />
    )
}

export default ProductDetails
