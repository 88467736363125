import React, { useState, useEffect } from 'react'
import tick from '../../assets/Group 25.svg'
import EditIcon from './../../assets/edit-icon.png'
import DeleteIcon from './../../assets/delete-icon.png'
import { Redirect, useHistory } from 'react-router-dom'
import AdminNav from '../AdminNav/AdminNav'
import Navbar from '../Navbar/Navbar'
import './../AdminNav/admincommon.css'
import { all_product, AdminDeleteProduct } from '../API/urls'
import AdminProductDetailModal from './AdminProductDetailModal'
import AdminAddProductModal from './AdminAddProductModal'
import AdminProductDeleteModal from './AdminProductDelete'
import AdminEditProductModal from './AdminEditProductModal'
import { configuredToastSuccess } from '../toast/toast'
import { Table } from 'reactstrap'
import Carousel from './Carousel';


const Products = () => {
    const [productModal, setproductModal] = useState(false)
    const [addProductModal, setaddProductModal] = useState(false)
    const [deleteProductModal, setdeleteProductModal] = useState(false)
    const [editProductModal, seteditProductModal] = useState(false)
    const [current, setcurrent] = useState(-1)
    const [products, setproducts] = useState([])
    const [query, setQuery] = useState("");
    const [dbData, setDashboardData] = useState(null);
    const [refreshProducts, setRefreshProducts] = useState(0);
    const [showProductModalCom, setShowProductModalCom] = useState(false)

    let history = useHistory()

    useEffect(() => {
        getDashboardData();
    }, [addProductModal, refreshProducts, deleteProductModal])

    //VIEW PRODUCT MODAL


    const updateInput = async (query) => {
        if (dbData) {
            const filtered = dbData.filter(item => {
                return item['modelName'].toLowerCase().includes(query.toLowerCase()) || JSON.stringify(item['SKU_code']).includes(query) || JSON.stringify(item['Price']).includes(query)
            })
            await setQuery(query);
            await setproducts(filtered);
        }
    }

    const showProductModal = (status, index) => {
        setcurrent(index)
        setproductModal(status)
    }

    const closeProductModal = () => {
        setproductModal(false)
    }

    //CREATE PRODUCT MODAL
    const showAddProductModal = (status) => {
        setaddProductModal(status)
    }
    const closeAddProductModal = () => {
        setaddProductModal(false)
    }

    //DELETE PRODUCT MODAL
    const updateProducts = async (id) => {
        await deleteProductFromServer(id)
    }

    const showDeleteProductModal = (status, index) => {
        setcurrent(index)
        setdeleteProductModal(status)
    }

    const closeDeleteProductModal = () => {
        setdeleteProductModal(false)
    }

    //EDIT PRODUCT MODAL
    const showEditProductModal = (status, index) => {
        setcurrent(index)
        seteditProductModal(status)
        setShowProductModalCom(true)
    }

    const closeEditProductModal = () => {
        seteditProductModal(false)
    }



    const getDashboardData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };

        await fetch(all_product, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => {

                if (result.success || result.status) {
                    console.log("asbansmkolknabxnm,= =====", result)
                    setproducts(result.data)
                    setDashboardData(result.data)
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
            });
    }

    const deleteProductFromServer = async (id) => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(AdminDeleteProduct(id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT : ", result)
                if (result) {
                    setproducts(products.filter(item => item.product_id !== id))
                    configuredToastSuccess("Product Removed Successfully", "!!")
                }
                else {
                    configuredToastSuccess("Request Failed", "!!")
                }
            })
            .catch((error) => {
                // console.log(error)
            });
    }


    console.log(products, "products")
    return (
        localStorage.getItem('isLoggedIn')
            ? <div className="overflow-x-hidden overflow-y-hidden lg:h-full mx-3">
                <Navbar admin={true} />
                <div className="grid grid-cols-2 gap-12 2xl:flex xl:flex lg:flex md:flex max-h-screen mx-4 ">
                    <div className="flex flex-col justify-start">
                        <AdminNav active="nav_item_3" />
                    </div>
                    <div className="border-4 border-black rounded-md w-5/6 overflow-y-auto overflow-x-hidden h-max adminMainContainer">
                        <div className="py-4">
                            <div className="flex justify-between  py-6 px-8 ">

                                <h1 className="adminHeaderH1 pt-0">Products</h1>
                                <div className="flex justify-end items-center">
                                    <label htmlFor="search-form">
                                        <input
                                            type="search"
                                            name="search-form"
                                            id="search-form"
                                            className="search-input"
                                            placeholder="Search for..."
                                            value={query}
                                            onChange={(e) => updateInput(e.target.value)}
                                        />
                                    </label>
                                    <button
                                        className="bg-black hover:bg-black-700 text-white text-l xs:text-xs font-bold btn-1"
                                        onClick={() => showAddProductModal(true)}
                                    >
                                        Add New +
                                    </button>

                                </div>
                                {/* <button className="adminProductBtnAdd">Ad New +</button> */}
                            </div>
                            <div className="mx-8 my-4">
                                <Table borderless responsive>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Model Name</th>
                                            <th>Model Description</th>
                                            <th>SKU Code</th>
                                            <th>Price</th>
                                            <th>View</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="border-2 border-gray-400 rounded-md slider">
                                                            {/* <img src={item.image} alt="img1" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                                                            <Carousel image={item.image} image1={item.image1} image2={item.image2} image3={item.image3} />
                                                        </div>
                                                        
                                                    </td>
                                                    <td> <h1 className="2xl:text-2xl text-md font-bold">{item.modelName}</h1></td>
                                                    {/* <td> <h1 className="2xl:text-2xl text-md font-bold"  dangerouslySetInnerHTML={{__html: item.description}}></h1></td> */}
                                                    <td>  <h1 className="2xl:text-xl text-sm font-bold">{item.SKU_code}</h1></td>
                                                    <td> <h1 className="2xl:text-xl text-sm font-bold">INR {item.Price}</h1></td>
                                                    <td><h1 className="2xl:text-xl text-sm cursor-pointer font-bold" onClick={() => showProductModal(true, index)} >View</h1></td>
                                                    <td> <img className="w-12 h-12" src={EditIcon} alt="Edit" onClick={() => showEditProductModal(true, index)} /></td>
                                                    <td><img className="w-12 h-12" src={DeleteIcon} alt="Delete" onClick={() => showDeleteProductModal(true, item.product_id)} />
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <AdminProductDetailModal
                    show={productModal}
                    onCancel={closeProductModal}
                    data={products[current]}
                />
                <AdminAddProductModal
                    show={addProductModal}
                    onCancel={closeAddProductModal}
                />
                <AdminProductDeleteModal
                    show={deleteProductModal}
                    onCancel={closeDeleteProductModal}
                    id={current}
                    onConfirm={updateProducts}
                />
                {
                    showProductModalCom && 
                <AdminEditProductModal
                    show={editProductModal}
                    onCancel={closeEditProductModal}
                    data={products[current]}
                    updatingFunction={setRefreshProducts}
                    updateProducts={refreshProducts}
                />
            }

            </div >
            : <Redirect to="/" />
    )
}

export default Products
