import React from 'react'
import share from '../../assets/Share.svg'
import file from '../../assets/Share/Group 119.svg'
import whatsapp from '../../assets/Share/Group 116.svg'
import './share.css';
import { Link, Redirect } from 'react-router-dom'

const Share = () => {
    return (
        localStorage.getItem('isLoggedIn')
            ? <div className=" flex justify-center">
                <div className="border-black border-4 flex flex-col justify-center my-56 w-3/5 xs:w-full xs:mx-8">
                    <img className=" self-start mx-28 xs:mx-4 my-8" src={share} alt="share" />
                    <div className="border-black border-2 mx-auto w-4/5 h-16">

                    </div>
                    <div className="flex flex-row my-10 justify-between xs:mx-6 mx-12 2xl:mx-28">
                        <Link>
                            <img className="w-16" src={file} alt="file" />
                        </Link>
                        <Link>
                            <img className="w-16" src={whatsapp} alt="whatsapp" />
                        </Link>
                    </div>
                </div>
            </div>
            : <Redirect to='/' />
    )
}

export default Share
