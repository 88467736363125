import { global_url } from './server'

const signUp = `${global_url}/signUp`
const verify_otp = `${global_url}/verify_otp`
const login = `${global_url}/Login`
const question1 = `${global_url}/question1`
const question2 = `${global_url}/question2`
const question3 = `${global_url}/question3`
const question4 = `${global_url}/question4`
const question5 = `${global_url}/question5`
const question6 = `${global_url}/question6`
const all_product = `${global_url}/all_product`
const all_banner = `${global_url}/Banner`
const add_banner = `${global_url}/AddBanner`
const add_to_cart = `${global_url}/add_to_cart`
const view_cart = `${global_url}/ViewCart`
const view_cart_edit = `${global_url}/ViewCartEdit`
const delete_from_cart = `${global_url}/destroyCart`
const get_collaterals = `${global_url}/all_collateral`
const increase_dec = `${global_url}/addProductDetails`
const dashboardData = `${global_url}/BOQDashboard`
const addUserDetails = `${global_url}/addUserDetails`
const shareCart = (id) => `${global_url}/PublicViewCart/${id}`
const profile = `${global_url}/userProfile`
const EditProfile = `${global_url}/EditUserProfile`
const allDealer = `${global_url}/AllDealerData`
const add_new_BOQ = `${global_url}/add_new_BOQ`
const customerDetails = `${global_url}/customerDetails`
const editDelear = `${global_url}/editStoreDealer`

const delete_cart_details = `${global_url}/cartDetailsdestroy`
const logout = `${global_url}/logout`
const filterOptionsImage = `${global_url}/all_images`
const discountOffer = `${global_url}/dealerDiscount`
const delear = `${global_url}/Dealers`
const getAuthDealer = `${global_url}/getAuthDealer`

//ADMIN PRODUCTS
const AdminDeleteProduct = (id) => {
    return `${global_url}/destroyProduct/${id}`
}

const DeleteBanner = (id) => {
    return `${global_url}/destroyBanner/${id}`
}

const AdminAddProduct = `${global_url}/AddProduct`

const AdminEditProduct = (id) => {
    return `${global_url}/updateProduct/${id}`
}

const getProductType = `${global_url}/ProductType`


//ADMIN COLLATERALS
const AdminGetCollateral = `${global_url}/Admincollateral`

const AdminAddCollateral = `${global_url}/AddCollateral`

const AdminDeleteCollateral = (id) => {
    return `${global_url}/destroyCollateral/${id}`
}

const AdminEditCollateral = (id) => {
    return `${global_url}/updateCollateral/${id}`
}

//ADMIN BOQS
const AdminGetBOQs = `${global_url}/get_BOQ`
const AdminGetCSV = (startDate, endDate) => `${global_url}/export?start_date=${startDate}&end_date=${endDate}`


// Dealers
const getDealers = `${global_url}/Dealer`
const adminDeleteDealer = (id) => {
    return `${global_url}/destroyDealer/${id}`
}
const addDealer = `${global_url}/AddDealer`
const getState = `${global_url}/SelectState`
const updateDealer = (id) => `${global_url}/updateDealer/${id}`


// Users
const allUsers = `${global_url}/AllUser`
const adminDeleteUser = (id) => `${global_url}/destroyUser/${id}`
const addUser = `${global_url}/AddUser`
const updateUser = (id) => `${global_url}/updateUser/${id}`


// Public


// Admin Dash

const adminData = `${global_url}/AdminDashboard`

export {
    signUp,
    login,
    verify_otp,
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    getProductType,
    all_product,
    all_banner,
    add_banner,
    DeleteBanner,
    add_to_cart,
    view_cart,
    delete_from_cart,
    get_collaterals,
    increase_dec,
    dashboardData,
    addUserDetails,
    shareCart,
    profile,
    EditProfile,
    allDealer,
    add_new_BOQ,
    customerDetails,
    editDelear,
    delete_cart_details,
    logout,
    filterOptionsImage,
    view_cart_edit,
    AdminDeleteProduct,
    AdminAddProduct,
    AdminGetCollateral,
    AdminEditProduct,
    AdminAddCollateral,
    AdminDeleteCollateral,
    AdminEditCollateral,
    AdminGetBOQs,
    AdminGetCSV,
    getDealers,
    adminDeleteDealer,
    addDealer,
    getState,
    updateDealer,
    allUsers,
    adminDeleteUser,
    addUser,
    updateUser,
    adminData,
    discountOffer,
    delear,
    getAuthDealer,
};

