import React, { useEffect, useState } from "react";
import { addDealer, getState } from "../API/urls";
import { configuredToastSuccess } from "../toast/toast";

const AdminAddDealerModal = ({ onCancel, show }) => {
    const initialState = {
        dealer_code: "",
        dealer_name: "",
        state: "",
        city: "",
    }
    const [showFlag, setshowFlag] = useState(false);
    const [formInfo, setformInfo] = useState(initialState);
    const [states, setStates] = useState([]);



    const getStateList = async () => {
        let token = localStorage.getItem('token');

        var requestOptions = {
            headers: { "Authorization": `Bearer ${token}` },
        };
        await fetch(getState, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setStates([...result.data.map((item) => item.state)]);
            })
    }

    useEffect(() => {
        getStateList();
    }, [])

    const handleTerminate = () => {
        setformInfo({ ...initialState })
        onCancel()
        setshowFlag(false)
    }

    const handleInputChange = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        setformInfo({ ...formInfo, [key]: val })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        addDealerToServer()
        if (showFlag) handleTerminate()
    }

    const addDealerToServer = async () => {
        let token = localStorage.getItem('token');
        var formdata = new FormData();
        Object.keys(formInfo).map(item => {
            formdata.append(item, formInfo[item]);
        })

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(addDealer, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT :", result)
                if (result.status)
                    configuredToastSuccess("Dealer Added", "!!")
            })
            .catch((error) => {
                console.log("catch", error)
            });
    }


    if (show === false) return <></>
    else
        return (
            <>
                <div
                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    ADD DEALER
                                </h3>
                                <button
                                    className="ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={handleTerminate}
                                >
                                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        X
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className=" grid grid-cols-2">


                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    DEALER CODE
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    type="number"
                                                    placeholder="DEALER CODE"
                                                    name="dealer_code"
                                                    value={formInfo.dealer_code}
                                                    onChange={handleInputChange}
                                                    minLength={3}
                                                    required
                                                ></input>
                                            </div>
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    DEALER NAME
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    type="text"
                                                    placeholder="DEALER NAME"
                                                    name="dealer_name"
                                                    value={formInfo.dealer_name}
                                                    onChange={handleInputChange}
                                                    required
                                                ></input>
                                            </div>
                                        </div>


                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-accessories">
                                                    STATES
                                                </label>
                                                <select
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    placeholder="State"
                                                    name="state"
                                                    value={formInfo.state}
                                                    onChange={handleInputChange}
                                                    required
                                                >   <option selected deafultValue="null">Select State</option>
                                                    {
                                                        states &&
                                                        states.map((state) =>
                                                        (
                                                            <option value={state}>{state}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {
                                                formInfo.state != "" && formInfo.state != null ?
                                                    <div className="w-full px-3">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-accessories">
                                                            CITY
                                                        </label>
                                                        <input
                                                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                            placeholder="CITY"
                                                            name="city"
                                                            type="text"
                                                            value={formInfo.city}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                        </input>
                                                    </div> :
                                                    ''
                                            }
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="border-2 border-black rounded-md mr-4 text-white bg-black font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                            onClick={() => setshowFlag(true)}
                                            disabled={formInfo.dealer_code.length >= 3 ? false : true}
                                        >
                                            Save & Close
                                        </button>
                                        <button
                                            className="border-2 border-red-600 rounded-md text-white bg-red-600 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mr-4"
                                            type="button"
                                            onClick={handleTerminate}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        );
}

export default AdminAddDealerModal;
