import React, { useState, useEffect } from 'react'
import pict from '../../assets/Dashboard- Dealer/KohlerLogo.png'
import { useParams } from 'react-router-dom'
import './Cart.css';
import { shareCart } from '../API/urls'



const ShareCart = () => {
    const [loading, toggleLoading] = useState(false)
    let [cartList, setCart] = useState([])
    const [name, setName] = useState("");
    const [total, setTotal] = useState(0)

    const { id } = useParams();



    useEffect(() => {
        handleProduct();
    }, [id])


    const handleProduct = async () => {
        toggleLoading(true)
        var requestOptions = {
            method: 'GET',
        };

        await fetch(shareCart(id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status) {
                    // console.log(result)
                    // console.log(result.data[0]['name'])

                    if (result.data[0] !== 'Record Not Found') {
                        let sum = 0
                        result.data.map((ele, id) => sum = sum + parseInt(ele.NEW_MRP))
                        setTotal(sum)

                        // console.log(result.data)
                        setName(result.data[0]['name'])
                        setCart(result.data);
                    }
                    else {
                        setCart([])
                    }
                    toggleLoading(false)
                }
                else {
                    // console.log(`error : ${result.data}`)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                toggleLoading(false)
            });
        toggleLoading(false)
    }


    return (

        <div className="overflow-x-hidden">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src={pict} alt="logo" className="img-fluid mx-auto" />
                </div>
            </div>

            <div className="row mx-9 mt-4">
                <div className="col-lg-12">
                    <p className="name pl-2 text-bold">Name: {name}</p>
                    <p className="name pl-2 text-bold">BOQ ID: {`P-ID${id}`}</p>
                </div>
            </div>


            <div className="flex flex-col my-12 mx-9" style={{ alignSelf: 'center' }}>
                {
                    cartList.length !== 0
                        ? cartList.map((ele, index) => {
                            if (index < 5)
                                return (
                                    <div className="mainCartContainer grid grid-cols-4 my-5 w-full share-cart" key={index} >
                                        <img alt={`product-${index}`} src={ele.image} style={{ backgroundColor: '#e3e3e3', justifyContent: 'center', alignItems: 'center' }} />
                                        {/* DESKTOP VIEW */}
                                        <div className="flex flex-col text-center justify-center handleDesktopViewOfGrid">
                                            <p className="txt1">{ele.Model_name}</p>
                                            <p className="txt">{ele.SKU_code}, {ele.Type}</p>
                                        </div>
                                        <div className="flex flex-col text-center justify-center align-content-center handleDesktopViewOfGrid">
                                            <p className="txt1">Quantity</p>
                                            <p className="txt">{ele.quantity}</p>
                                        </div>
                                        <div className="flex flex-col text-center justify-center align-content-center handleDesktopViewOfGrid">
                                            <p className="txt1">Price</p>
                                            <p className="txt">&#8377; {ele.NEW_MRP}</p>
                                        </div>

                                        {/* MOBILE VIEW */}
                                        <div className="flex flex-col justify-center pl-4 items-start handleMobileViewOfGridWithFlex">
                                            <div className="mobile-view-cart-text">
                                                <p className="cartMobileFont font-bold">{ele.Model_name}</p>
                                                <p className="cartMobileFont">{ele.SKU_code}, {ele.Type}</p>
                                                <p className="cartMobileFont"><span>&#8377;{ele.NEW_MRP}</span></p>
                                                <p className="cartMobileFont">Qty: {ele.quantity}</p>
                                            </div>

                                        </div>





                                    </div>
                                )
                            return (<></>)
                        }



                        )
                        : <h1 className="text-xl my-9 2xl:my-20 xs:text-sm text-gray-500 text-center">Opps...No product found!!</h1>
                }




                {
                    cartList.length !== 0
                        ? <div style={{ marginBottom: 40 }} >

                            <div className="flex flex-row justify-end">
                                <h1 className="text-3xl xs:text-lg font-bold">Total: </h1>{"  "}
                                <h1 className="text-3xl xs:text-lg font-bold">Rs. {total}</h1>
                            </div>

                        </div>
                        : <></>
                }

                {
                    cartList.length !== 0 &&
                    <div className=" my-9 2xl:my-20">
                        <div className="text-xl xs:text-sm text-black-500">Terms & Conditions</div>
                        <div className="text-l xs:text-sm text-gray-500">1)  Additional charges of INR 2000 per bathroom will be applicable for installation</div>
                        <div className="text-l xs:text-sm text-gray-500">2) MRPs are for reference only and can change without prior notice</div>
                        <div className="text-l xs:text-sm text-gray-500">3) The standard height for shower enclosure is 2000 mm</div>
                        <div className="text-l xs:text-sm text-gray-500">4) Depending on the site situations, the purchase of ledge may be mandatory</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShareCart
