import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import cartIcon from "../../assets/Cart/Group 61.svg";
import cartIconMobile from "./../../assets/Cart/Group 70.svg";
import BackIcon from "./../../assets/Cart/Group 58.svg";
import { Redirect, useHistory } from "react-router-dom";
import cross from "../../assets/Group 20.svg";
import "./Cart.css";
import {
  delete_from_cart,
  view_cart,
  increase_dec,
  addUserDetails,
  customerDetails,
  delete_cart_details,
  discountOffer,
  delear,
  editDelear,
  getAuthDealer,
} from "../API/urls";

import { configuredToastSuccess } from "../toast/toast";
import { host } from "../API/server";

const Cart = () => {
  const [loading, toggleLoading] = useState(false);
  let [cartList, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerRemarks, setCustomerRemarks] = useState("");
  const [storename, setstorename] = useState("");
  const [discount, setdiscount] = useState(0);
  const [offer, setoffer] = useState("");
  const [account, setAccount] = useState();
  const [delear_name, setdelear_name] = useState("");
  const [delearName, setdelearName] = useState("");

  console.log("name", delearName);
  //    const discountvalue = discount
  //     const bydefaultdiscount =JSON.stringify(discountvalue)

  let history = useHistory();

  useEffect(() => {
    defaultdelear();
    handleCustomer();
    handleProduct();
    handleDelear();
    // discountoffer();
  }, []);

  const defaultdelear = async () => {
    toggleLoading(true);
    let token = localStorage.getItem("token");

    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(getAuthDealer, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        console.log("Delear name ", result);
        if (result.status && result.data !== "No Record Found") {
          // setdelear_name(result.data.dealer_name);
          setdelearName(result.data.dealer_name);
          setstorename(result.data.dealer_name);
          // setstorename(result.data.store_name)
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });

    toggleLoading(false);
  };

  const handleDelear = async () => {
    toggleLoading(true);
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");

    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(`${editDelear}/${boq_id}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        console.log("carts delear detail", result);
        if (result.status && result.data !== "No Record Found") {
          setdelearName(result.data.salesperson_name);
          setstorename(result.data.store_name);
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });

    toggleLoading(false);
  };

  const handleCustomer = async () => {
    toggleLoading(true);
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");

    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(`${customerDetails}/${boq_id}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        console.log("carts customer detail", result);
        if (result.status && result.data !== "No Record Found") {
          setCustomerName(result.data.name);
          setCustomerRemarks(result.data.remark);
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });

    toggleLoading(false);
  };

  const handleDownloadPdf = async () => {
    let boq_id = localStorage.getItem("boq_id");

    let url = `${host}/pdfview/${boq_id}`;
    setTimeout(() => {
      let link = document.createElement("a");
      link.href = url;
      return link.click();
    }, 1000);

    // if(customerName !== "" && customerRemarks !== ""){
    //     insertUserDetails(customerName, customerRemarks);
    // }
    // else{
    // insertDelearDetails(delearName,storename);
    // }
    insertUserDetails(customerName, customerRemarks);
    insertDelearDetails(delearName, storename);
  };
  const handleDownloadPdfuser = async () => {
   
    let boq_id = localStorage.getItem("boq_id");

    let url = `${host}/customerpdfview/${boq_id}`;
    setTimeout(() => {
      let link = document.createElement("a");
      link.href = url;
      return link.click();
    }, 1000);

    insertUserDetails(customerName, customerRemarks);
    insertDelearDetails(delearName, storename);
  };

  const handleWhatsappShare = () => {
    insertUserDetails(customerName, customerRemarks);
    insertDelearDetails(delearName, storename);
    let boq_id = localStorage.getItem("boq_id");
    console.log(window.location.origin);
    window.open(
      `https://api.whatsapp.com/send?phone=&text=${window.location.origin}/shareCart/${boq_id}`
    );
  };

  const insertDelearDetails = async (delearName, storename) => {
    toggleLoading(true);
    // console.log(" in insert user")
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");
    let user_type = localStorage.getItem("account_status");

    var formdata = new FormData();

    formdata.append("store_name", storename);
    formdata.append("salesperson_name", delearName);
    formdata.append("boq_id", boq_id);
    formdata.append("user_id", user_type);

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    await fetch(delear, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        // configuredToastSuccess(`${result.data}`, "!!")
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  };

  const insertUserDetails = async (name, customerRemarks) => {
    toggleLoading(true);
    // console.log(" in insert user")
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");

    var formdata = new FormData();

    formdata.append("remark", customerRemarks);
    formdata.append("name", name);
    formdata.append("boq_id", boq_id);

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    await fetch(addUserDetails, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        configuredToastSuccess(`${result.data}`, "!!");
        toggleLoading(false);
        setdelearName(result.data.delearName);
        setstorename(result.data.dealer_name);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  };

  useEffect(async () => {
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");
    let user_type = localStorage.getItem("account_status");
    var formdata = new FormData();
    formdata.append("price_discount", 0);
    formdata.append("boq_id", boq_id);
    formdata.append("user_type", user_type);
    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    await fetch(discountOffer, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        // configuredToastSuccess(`${result.data}`, "!!")
        setoffer(result.data);
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  }, [total]);

  const discountoffer = async (discount) => {
    toggleLoading(true);
    setdiscount(discount);

    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");
    let user_type = localStorage.getItem("account_status");
    // let user_type = localStorage.getItem('user_type')

    var formdata = new FormData();

    formdata.append("price_discount", discount);
    formdata.append("boq_id", boq_id);
    formdata.append("user_type", user_type);

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    await fetch(discountOffer, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        // configuredToastSuccess(`${result.data}`, "!!")
        setoffer(result.data);
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  };

  const handleProduct = async () => {
    toggleLoading(true);
    let token = localStorage.getItem("token");
    let user_type = localStorage.getItem("account_status");
    setAccount(user_type);
    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let boq_id = localStorage.getItem("boq_id");

    await fetch(`${view_cart}/${boq_id}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        console.log("product detail", result);

        if (result.status) {
          if (result.data[0] !== "Record Not Found") {
            let sum = 0;
            result.data.map((ele, id) => (sum = sum + parseInt(ele.NEW_MRP)));
            setTotal(sum);
            setCart(result.data);
            // setUserId(result.data[0].user_id)
          } else {
            setCart([]);
          }
          toggleLoading(false);
        } else {
          // console.log(`error : ${result.data}`)
          toggleLoading(false);
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });

    toggleLoading(false);
  };

  const deleteProduct = async (ele) => {
    toggleLoading(true);
    // console.log("delete cart = ", added)
    // console.log("delete product ", ele)
    let id = ele.id;

    let cart_id = ele.cartDeatils_id;
    let token = localStorage.getItem("token");
    var requestOptions = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(`${delete_from_cart}/${id}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then(async (result) => {
        await handleProduct();
        configuredToastSuccess("Product removed from Cart", "!!");
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });

    toggleLoading(true);
    await fetch(`${delete_cart_details}/${cart_id}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result)
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  };

  const handleCounter = async (cart_id, product_id, qty) => {
    toggleLoading(true);
    let token = localStorage.getItem("token");
    let boq_id = localStorage.getItem("boq_id");

    var formdata = new FormData();

    formdata.append("product_id", product_id);
    formdata.append("cart_id", cart_id);
    formdata.append("quantity", qty);
    formdata.append("boq_id", boq_id);

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    };

    await fetch(`${increase_dec}`, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then(async (result) => {
        await handleProduct();
        toggleLoading(false);
        configuredToastSuccess("Quantity Updated", "!!");
        toggleLoading(false);
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
        toggleLoading(false);
      });
  };

  const QuantityElement = (ele, flag) => {
    return (
      <div className={`flex flex-col text-center justify-center ${flag}`}>
        {flag === "handleDesktopViewOfGrid" && <p className="txt1">Qty</p>}
        <div class="QuantityContainer">
          <button
            onClick={() =>
              handleCounter(ele.id, ele.product_id, ele.quantity - 1)
            }
            style={{ paddingRight: 10, outline: "none" }}
          >
            -
          </button>
          <span className="count text-center">{ele.quantity}</span>
          <button
            onClick={() =>
              handleCounter(ele.id, ele.product_id, ele.quantity + 1)
            }
            style={{ paddingLeft: 10, outline: "none" }}
          >
            +
          </button>
        </div>
      </div>
    );
  };

  return localStorage.getItem("isLoggedIn") ? (
    <div className="overflow-hidden">
      <Navbar />
      {/* DESKTOP VIEW */}
      <div className="Cart mx-9 handleDesktopViewOfGrid">
        <div>
          <a href="/results">
            <img src={BackIcon} alt="cart-icon" />
          </a>
        </div>
        <div>
          <img src={cartIcon} alt="cart-icon" /> <p className="cart">Cart</p>
        </div>
      </div>
      {/* Mobile VIEW */}
      <div className="Cart mx-9 handleMobileViewOfGridWithFlex">
        <div>
          <a href="/results">
            <img src={BackIcon} alt="cart-icon" />
          </a>
        </div>
        <img src={cartIconMobile} alt="cart-icon" />
      </div>

      <div className="flex flex-col my-12 mx-9" style={{ alignSelf: "center" }}>
        {cartList.length !== 0 ? (
          cartList.map((ele, index) => {
            if (index < 5)
              return (
                <div
                  className="mainCartContainer grid grid-cols-5 gap-5 my-5 w-full"
                  key={index}
                >
                  <div className="flex flex-col justify-center border-2 border-gray-400">
                    <img
                      alt={`product-${index}`}
                      src={ele.image}
                      className="2xl:h-28  h-35 md:h-24 lg:h-24 xs:h-20 sm:h-20"
                      style={{
                        backgroundColor: "#e3e3e3",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                  {/* DESKTOP VIEW */}
                  <div className="flex flex-col text-center justify-center handleDesktopViewOfGrid">
                    <p className="txt1">{ele.Model_name}</p>
                    <p className="txt">
                      {ele.SKU_code}, {ele.Type}
                    </p>
                  </div>
                  {QuantityElement(ele, "handleDesktopViewOfGrid")}
                  <div className="flex flex-col text-center justify-center align-content-center handleDesktopViewOfGrid">
                    <p className="txt1">Price</p>
                    <p className="txt">&#8377; {ele.NEW_MRP}</p>
                  </div>
                  {/* MOBILE VIEW */}
                  <div className="flex flex-col justify-center items-start handleMobileViewOfGridWithFlex">
                    <div>
                      <p className="cartMobileFont font-bold">
                        {ele.Model_name}
                      </p>
                      <p className="cartMobileFont">
                        {ele.SKU_code}, {ele.Type}
                      </p>
                      <div className="flex text-center justify-start align-content-center">
                        <p className="cartMobileFont font-bold">
                          <span>&#8377;{ele.NEW_MRP}</span>
                        </p>
                      </div>
                      {QuantityElement(ele, "handleMobileViewOfGrid")}
                    </div>
                  </div>
                  <div className="flex flex-col text-center justify-center">
                    {/* <p className="txt1">Action</p>
                                                {QuantityElement(ele, "handleMobileViewOfGrid")} */}
                    <div className="flex text-center justify-center align-content-center my-2">
                      <button
                        style={{ outline: "none" }}
                        onClick={() => deleteProduct(ele)}
                      >
                        <img
                          className="w-12 sm:w-8 xs:w-8"
                          src={cross}
                          alt="cross"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            return <></>;
          })
        ) : (
          <h1 className="text-xl my-6  2xl:my-20 xs:text-sm text-gray-500 text-center">
            No product found!!
          </h1>
        )}

        {cartList.length !== 0 && account == 2 ? (
          <div className="Discount">
            <div className="Price">
              <h1 className="text-3xl xs:text-lg font-bold">Total Price: </h1>
              {"  "}
              <h1 className="text-3xl xs:text-lg font-bold">Rs. {total}</h1>
            </div>

            <div className="Discountbox">
              <h1 className="text-3xl xs:text-lg font-bold discountitem1">
                Discount:{" "}
              </h1>
              {"  "}
              <input
                value={discount}
                onChange={(e) => discountoffer(e.target.value)}
                className=" border-gray-200 border-2 mt-4 text-xl discountitems focus:shadow-outline rounded-md"
                type="number"
                placeholder="Discount %"
              />

              <input
                disabled={discount === ""}
                //  defaultValue ={}
                value={offer}
                className=" border-gray-400 border-2 mt-4 text-xl discountitem focus:shadow-outline rounded-md"
                type="number"
                placeholder="Discount"
              />
            </div>
            <div className="TotalDiscount">
              <h1 className="text-3xl xs:text-lg font-bold">
                Discounted Price:{" "}
              </h1>
              {"  "}
              <h1 className="text-3xl xs:text-lg font-bold"> {offer}</h1>
            </div>
          </div>
        ) : (
          <></>
        )}

        {cartList.length !== 0 && account == 1 ? (
          <div style={{ marginBottom: 40 }}>
            <div className="flex flex-row justify-end">
              <h1 className="text-3xl xs:text-lg font-bold">Total Price: </h1>
              {"  "}
              <h1 className="text-3xl xs:text-lg font-bold">Rs. {total}</h1>
            </div>
          </div>
        ) : (
          <></>
        )}
        {cartList.length !== 0 && account == 3 ? (
          <div style={{ marginBottom: 40 }}>
            <div className="flex flex-row justify-end">
              <h1 className="text-3xl xs:text-lg font-bold">Total Price: </h1>
              {"  "}
              <h1 className="text-3xl xs:text-lg font-bold">Rs. {total}</h1>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {account == 2 ? (
        <>
          <div className="Customer">
            <div className="">
              <div className="flex justify-start">
                <p className="customer">Customer Details</p>
              </div>
              <div className="my-4 flex justify-center flex-col">
                <input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-4 text-md xs:px-8 px-3  py-2 text-black-700 self-center focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Name"
                />
                <br />
                <input
                  value={customerRemarks}
                  onChange={(e) => setCustomerRemarks(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-1 text-md  py-2 px-3   xs:px-8 text-black-700 self-center  focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Remarks"
                />
                <br />
              </div>
            </div>
            <div className="">
              <div className="flex justify-center">
                <p className="customer">Dealer Details</p>
              </div>
              <div className="my-4 flex justify-start flex-col mx-4">
                <input
                  value={delearName}
                  onChange={(e) => setdelearName(e.target.value)}
                  className="w-500 border-gray-400 border-2 mt-4 text-md delear   py-2 text-black-700 self-center focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Sales Person name"
                />
                <br />
                <input
                  value={storename}
                  onChange={(e) => setstorename(e.target.value)}
                  className="w-500 border-gray-400 border-2 mt-1 text-md  py-2  delear  text-black-700 self-center  focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Store Name"
                />
                <br />
              </div>
            </div>
          </div>

          <div className="my-4 flex justify-center gap-10">
            <button
              disabled={customerName === ""}
              onClick={() => handleDownloadPdf()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Download PDF
            </button>
            <button
              disabled={customerName === ""}
              onClick={() => handleWhatsappShare()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Share on Whatsapp
            </button>
          </div>
        </>
      ) : account == 3 ? (
        <>
          <div className="Customer">
            <div className="">
              <div className="flex justify-start">
                <p className="customer">Customer Details</p>
              </div>
              <div className="my-4 flex justify-center flex-col">
                <input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-4 text-md xs:px-8 px-3  py-2 text-black-700 self-center focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Name"
                />
                <br />
                <input
                  value={customerRemarks}
                  onChange={(e) => setCustomerRemarks(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-1 text-md  py-2 px-3   xs:px-8 text-black-700 self-center  focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Remarks"
                />
                <br />
              </div>
            </div>
            {/* <div className=''>
                       <div className="flex justify-center">
                            <p className="customer">Dealer Details</p>
                        </div>
                        <div className="my-4 flex justify-start flex-col mx-4">
                            <input value={delearName} onChange={(e) => setdelearName(e.target.value)} className="w-500 border-gray-400 border-2 mt-4 text-md delear   py-2 text-black-700 self-center focus:shadow-outline rounded-md" type="text" placeholder="Sales Person name" /><br />
                            <input value={storename} onChange={(e) => setstorename(e.target.value)} className="w-500 border-gray-400 border-2 mt-1 text-md  py-2  delear  text-black-700 self-center  focus:shadow-outline rounded-md" type="text" placeholder="Store Name" /><br />
                        </div>
                       </div> */}
          </div>

          <div className="my-4 flex justify-center gap-10">
            <button
              disabled={customerName === ""}
              onClick={() => handleDownloadPdfuser()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Download PDF
            </button>
            <button
              disabled={customerName === ""}
              onClick={() => handleWhatsappShare()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Share on Whatsapp
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center">
            <div className="flex-col justify-start">
              <div className="flex justify-start">
                <p className="customer">Customer Details</p>
              </div>
              <div className="my-4 flex justify-start flex-col">
                <input
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-4 text-xl xs:px-8 px-3  py-2 text-black-700 self-center focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Name"
                />
                <br />
                <input
                  value={customerRemarks}
                  onChange={(e) => setCustomerRemarks(e.target.value)}
                  className="w-full border-gray-400 border-2 mt-1 text-xl  py-2 px-3   xs:px-8 text-black-700 self-center  focus:shadow-outline rounded-md"
                  type="text"
                  placeholder="Remarks"
                />
                <br />
              </div>
            </div>
          </div>

          <div className="my-4 flex justify-center gap-10">
            <button
              disabled={customerName === ""}
              onClick={() => handleDownloadPdfuser()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Download PDF
            </button>
            <button
              disabled={customerName === ""}
              onClick={() => handleWhatsappShare()}
              className="bg-black hover:bg-black-700 text-white text-lg xs:text-xs xs:px-6 xs:py-3 font-bold py-4 px-16 rounded-md"
            >
              Share on Whatsapp
            </button>
          </div>
        </>
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Cart;
