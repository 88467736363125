import React from 'react'

const AuthContext = React.createContext({
    type: ""
});

export const AuthContextProvidor = (props) => {

    const [type, setType] = React.useState(parseInt(localStorage.getItem("type")) || 0);


    const updateType = (type) => {
        setType(type)
    }

    const value = {
        type: type,
        updateType: updateType
    }
    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext
