import {
    Media,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { AdminEditProduct, getProductType } from "../API/urls";
import { configuredToastSuccess } from "../toast/toast";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromHTML} from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';


const AdminEditProductModal = ({ onCancel, show, data }) => {  
    console.log(data && data.description, "datatataatat")
    // console.log("EDIT DATA : ", data)
    const initialState = {
        SKU_code: "",
        type: "",
        model: "",
        status_type: "0",
        description: "",
        config: "",
        glass_Thickness: "",
        opening: "",
        running_Length: "",
        NEW_MRP: "",
        finish: "",
        pattern: "",
        glass_type: "",
        height: "",
        accessories: "",
        image: "",
        image1: "",
        image2: "",
        image3: "",
    }
    

    const [showFlag, setshowFlag] = useState(false)
    const [formInfo, setformInfo] = useState(initialState)
    const [types, setTypes] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createWithContent( ContentState.createFromBlockArray(
        convertFromHTML(data && data.description)
      )))
    const [Article, setArticle] = useState(data && data.description);

    
  

    useEffect(() => {
        console.log(data);
        if (data) {
            let sendToServer = {
                SKU_code: data.SKU_code == null ? "" :data.SKU_code,
                type: data.type == null ? "" : data.type,
                model: data.modelName == null ? "": data.modelName,
                status_type: data.status_type == null ? "" : data.status_type,
                description: data.description == null ? "" : data.description,
                config: data.config == null ? "" : data.config,
                glass_Thickness: data.glass_thickness == null ? "": data.glass_thickness,
                opening: data.opening == null ? "": data.opening,
                running_Length: data.Runnning_Length == null ? "": data.Runnning_Length,
                NEW_MRP: data.Price == null ? "": data.Price,
                finish: data.Finish == null ? "": data.Finish,
                pattern: data.pattern == null ? "": data.pattern,
                glass_type: data.glassType == null ? "": data.glassType,
                height: data.height == null ? "": data.height,
                accessories: data.accessories == null ? "": data.accessories,
                image: data.image,
                image1:data.image1,
                image2:data.image2,
                image3:data.image3,
            }
            setformInfo({ ...sendToServer })
        }
    }, [data, show])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setformInfo({...formInfo, description: convertedData})
       setArticle(formInfo.description)
       console.log(Article,"article")
       
      }

      

    const getType = async () => { 
        let token = localStorage.getItem('token');

        var requestOptions = {
            headers: { "Authorization": `Bearer ${token}` },
        };
        await fetch(getProductType, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result, "type");
                setTypes(result.data);
                
            })
    }

    useEffect(() => {
        getType();
    }, [])

    const onImageChange = (event) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0])
        img.onload = () => {
            if ((event.target.files[0].size) / 1000 <= 250 && img.width === 500 && img.height === 500) {
                setformInfo({ ...formInfo, image: event.target.files[0] })
            }
            else {
                configuredToastSuccess("Please upload proper image", "!")
                setformInfo({ ...formInfo, image: "" });
                document.getElementById("image").value = "";
            }
        }

    }
    
    const onImageChange1 = (event) => {
        let img1 = new Image();
        img1.src = window.URL.createObjectURL(event.target.files[0])
        img1.onload = () => {
            if (event.target.files && event.target.files[0]) {
                if((event.target.files[0].size)/1000 <250 && img1.width===500 && img1.height===500){
                setformInfo({ ...formInfo, image1: event.target.files[0] })
                }
                else{
                   configuredToastSuccess("Please upload proper image", "!")
                   setformInfo({ ...formInfo, image1: "" });
                   document.getElementById("image1").value = "";
                }
            }
        }
      }
      const onImageChange2 = (event) => {
        let img2 = new Image();
        img2.src = window.URL.createObjectURL(event.target.files[0])
        img2.onload = () => {
            if (event.target.files && event.target.files[0]) {
                if((event.target.files[0].size)/1000 <250 && img2.width===500 && img2.height===500){
                setformInfo({ ...formInfo, image2: event.target.files[0] })
                }
                else{
                   configuredToastSuccess("Please upload proper image", "!")
                   setformInfo({ ...formInfo, image2: "" });
                   document.getElementById("image2").value = "";
                }
            }
        }
    }

    const onImageChange3 = (event) => {
        let img3 = new Image();
        img3.src = window.URL.createObjectURL(event.target.files[0])
        img3.onload = () => {
            if (event.target.files && event.target.files[0]) {
                if((event.target.files[0].size)/1000 <250 && img3.width===500 && img3.height===500){
                setformInfo({ ...formInfo, image3: event.target.files[0] })
                }
                else{
                   configuredToastSuccess("Please upload proper image", "!")
                   setformInfo({ ...formInfo, image3: "" });
                   document.getElementById("image3").value = "";
                }
            }
        }
    }
    const handleTerminate = () => {
        setformInfo({ ...initialState })
        onCancel()
        setshowFlag(false)
    }

    const handleInputChange = (e) => {
        const key = e.target.name;
        const val = e.target.value;
        setformInfo({ ...formInfo, [key]: val })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addProductToServer()
        if (showFlag) handleTerminate()
    }

    const addProductToServer = async () => {
        let token = localStorage.getItem('token');
        var formdata = new FormData();
        console.log(formInfo, " : ", data.product_id)
        Object.keys(formInfo).map(item => {
            formdata.append(item, formInfo[item]);
        })

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: { "Authorization": `Bearer ${token}` },
        };

        await fetch(AdminEditProduct(data.product_id), requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("RESULT :", AdminEditProduct(data.product_id), " : ", result)
                configuredToastSuccess("Success", "!!")
            })
            .catch((error) => {
                // console.log("catch", error)
            });
    }


    if (show === false) return <></>
    else
        return (
            <>
                <div
                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    ADD PRODUCTS
                                </h3>
                                <button
                                    className="ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={handleTerminate}
                                >
                                    <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        X
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className="-mx-3 mb-6">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 px-2" for="image">
                                            IMAGE*
                                        </label>
                                        <input type="file" onChange={onImageChange} className="border-0 mb-3" id="image" />
                                        {
                                            formInfo.image && <button onClick={() => {
                                                setformInfo({ ...formInfo, image: "" })
                                                document.getElementById("image").value = ""
                                            }}>X</button>
                                        }
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                            {
                                                formInfo.image !== "" && <Media src={`${formInfo.image}`} className="img-fluid" />
                                            }
                                        </div>
                                        <input type="file" onChange={onImageChange1} className="border-0 mb-3" id="image1" />
                                        {
                                            formInfo.image1 && <button onClick={() => {
                                                setformInfo({ ...formInfo, image1: "" })
                                                document.getElementById("image1").value = ""
                                            }}>X</button>
                                        }
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                            {
                                                formInfo.image1 !== "" && <Media src={`${formInfo.image1}`} className="img-fluid" />
                                            }
                                        </div>
                                        <input type="file" onChange={onImageChange2} className="border-0 mb-3" id="image2" />
                                        {
                                            formInfo.image1 && <button onClick={() => {
                                                setformInfo({ ...formInfo, image2: "" })
                                                document.getElementById("image2").value = ""
                                            }}>X</button>
                                        }
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                            {
                                                formInfo.image2 !== "" && <Media src={`${formInfo.image2}`} className="img-fluid" />
                                            }
                                        </div>
                                        <input type="file" onChange={onImageChange3} className="border-0 mb-3" id="image3" />
                                        {
                                            formInfo.image1 && <button onClick={() => {
                                                setformInfo({ ...formInfo, image2: "" })
                                                document.getElementById("image3").value = ""
                                            }}>X</button>
                                        }
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                            {
                                                formInfo.image3 !== "" && <Media src={`${formInfo.image3}`} className="img-fluid" />
                                            }
                                        </div>
                                        
                                        <p className="text-sm text-gray-600">Note: Image dimensions should be 500x500px & size less than 250kbs!</p>
                                    </div>
                                    <div className=" grid grid-cols-2">


                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-sku-code">
                                                    SKU CODE*
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-sku-code"
                                                    type="text"
                                                    placeholder="SKU CODE"
                                                    name="SKU_code"
                                                    value={formInfo.SKU_code}
                                                    onChange={handleInputChange}
                                                    required
                                                ></input>
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-type">
                                                    TYPE
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-type"
                                                    type="text"
                                                    placeholder="Type"
                                                    name="type"
                                                    value={formInfo.type}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                                                    MODEL*
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-model"
                                                    type="text"
                                                    placeholder="Model"
                                                    name="model"
                                                    value={formInfo.model}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                                                    STATUS TYPE*
                                                </label>

                                                <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="status_type" value={formInfo.status_type} onChange={handleInputChange}>
                                                    {
                                                        types.map((item) => (
                                                            <option value={item.id} >{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-description">
                                                    description
                                                </label>
                                                {/* <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-description"
                                                    type="text"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={formInfo.description}
                                                    onChange={handleInputChange}
                                                >
                                                </input> */}
                                                <Editor
                                                // toolbarHidden
                                                className="!text-gray-700 bg-gray-200"
                                                //   value ={formInfo.description}
                                                //   defaultEditorState={formInfo.description}
                                                  
                                                  editorState={editorState}
                                                  toolbarClassName="toolbarClassName"
                                                  wrapperClassName="wrapperClassName"
                                                  editorClassName="editorClassName"
                                                  placeholder="Description"
                                                  onEditorStateChange={onEditorStateChange}
                                                  value={Article}
                                               />
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-config">
                                                    config
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-config"
                                                    type="text"
                                                    placeholder="Config"
                                                    name="config"
                                                    value={formInfo.config}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-glass-Thickness">
                                                    Glass Thickness
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-glass-Thickness"
                                                    type="text"
                                                    placeholder="Glass Thickness"
                                                    name="glass_Thickness"
                                                    value={formInfo.glass_Thickness}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>


                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-opening">
                                                    opening
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-opening"
                                                    type="text"
                                                    placeholder="Opening"
                                                    name="opening"
                                                    value={formInfo.opening}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-running-Length">
                                                    running Length
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-running-Length"
                                                    type="text"
                                                    placeholder="Running Length"
                                                    name="running_Length"
                                                    value={formInfo.running_Length}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-new-mrp">
                                                    price
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-new-mrp"
                                                    type="text"
                                                    placeholder="Price"
                                                    name="NEW_MRP"
                                                    value={formInfo.NEW_MRP}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-finish">
                                                    finish
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-finish"
                                                    type="text"
                                                    placeholder="Finish"
                                                    name="finish"
                                                    value={formInfo.finish}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-pattern">
                                                    pattern
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-pattern"
                                                    type="text"
                                                    placeholder="Pattern"
                                                    name="pattern"
                                                    value={formInfo.pattern}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-glass-type">
                                                    glass type
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-glass-type"
                                                    type="text"
                                                    placeholder="Glass Type"
                                                    name="glass_type"
                                                    value={formInfo.glass_type}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-height">
                                                    height
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-height"
                                                    type="text"
                                                    placeholder="Height"
                                                    name="height"
                                                    value={formInfo.height}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>

                                        {/* <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-status">
                                                Status Type
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="grid-status"
                                                type="text"
                                                placeholder="Status Type"
                                                name="status_type"
                                                value={formInfo.status_type}
                                                onChange={handleInputChange}
                                            >
                                            </input>
                                        </div>
                                    </div> */}

                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full px-3">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-accessories">
                                                    accessories
                                                </label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="grid-accessories"
                                                    type="text"
                                                    placeholder="Accessories"
                                                    name="accessories"
                                                    value={formInfo.accessories}
                                                    onChange={handleInputChange}
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </div>

                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        <button
                                            className="border-2 border-green-600 rounded-md mr-4 text-green-600 bg-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-green-600 hover:text-white focus:bg-green-800"
                                            type="submit"
                                            disabled={formInfo.SKU_code == "" || formInfo.image == "" || formInfo.model == "" || formInfo.status_type == ""}
                                            onClick={() => setshowFlag(true)}
                                        >
                                            Save & Close
                                        </button>
                                        <button
                                            className="border-2 border-red-600 rounded-md text-white bg-red-600 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mr-4"
                                            type="button"
                                            onClick={handleTerminate}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        );
}

export default AdminEditProductModal;
