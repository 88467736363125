import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { add_to_cart, view_cart_edit } from '../API/urls'
import add from '../../assets/Group 15.svg'
// import Carousel from '../dashboard/Carousel';
import Carousel from '../Products/Carousel';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import './style.css'
import { configuredToastSuccess } from '../toast/toast';

const Display = ({ fields, data, setData, dataFiltered, setDataFiltered, show, setShow }) => {
    const [q, setQ] = useState("");
   

    const updateInput = async (q) => {
        console.log(q);
        console.log(data);
        const filtered = data.filter(item => {
            return item['modelName'].toLowerCase().includes(q.toLowerCase()) || item['SKU_code'].toLowerCase().includes(q.toLowerCase())
        })
        await setQ(q);
        await setDataFiltered(filtered);
    }

    const sortFunction = async (data, fields, setDataFiltered, dataFiltered, sort) => {
        // console.log("sort value == ", sort)
        if (sort.value === 2) {
            dataFiltered.sort((a, b) => {
                return b.Price - a.Price;
            });
        }
        else {
            dataFiltered.sort((a, b) => {
                return a.Price - b.Price;
            });
        }

        // console.log(dataFiltered)
        setSortValue(sort)
        await setDataFiltered([...dataFiltered])
        
    }

    const options = [
        { value: 1, label: 'Low to High' },
        { value: 2, label: 'High to Low' },
    ];

    const [sortValue, setSortValue] = useState(null);
   
     

    return (

        localStorage.getItem('isLoggedIn')
            ? <div>
                <div className="flex justify-end filter-cover">
                    <div className="filter-btn">
                        <Link to="/filters-mob">
                            <button className="filter mx-4" >Filter</button>
                        </Link>
                    </div>
                    <div className="flex justify-end">
                        <label htmlFor="search-form" className="mx-5 none">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="search-input"
                                placeholder="Search for..."
                                value={q}
                                style={{ borderWidth: 2, borderColor: 'black', borderRadius: 5, padding: 10 }}
                                onChange={(e) => updateInput(e.target.value)}
                            />
                        </label>
                        <Dropdown
                            options={options}
                            onChange={(e) => sortFunction(data, fields, setDataFiltered, dataFiltered, e)}
                            value={sortValue}
                            placeholder="Sort By"
                        />
                    </div>
                </div>

                <label htmlFor="search-form" className="search ml-5">
                    <input
                        type="search"
                        name="search-form"
                        id="search-form"
                        className="search-input"
                        placeholder="Search for..."
                        value={q}
                        style={{ borderWidth: 2, borderColor: 'black', borderRadius: 5, padding: 10 }}
                        onChange={(e) => updateInput(e.target.value)}
                    />
                </label>

                <div>
                    <div className="grid grid-flow-row grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2">
                        <Product data={dataFiltered} show={show} />
                    </div>
                </div>

            </div>
            : <Redirect to="/" />
    )
}

function Product({ data, show }) { 
    // console.log(typeof (data), data)
    // console.log(Object.keys(data).length === 0 && data.constructor === Object)
    const [imagesForCarousel, setImagesimagesForCarousel]= useState([])
    const [loading, toggleLoading] = useState(false)
    const [id, setId] = useState([])

    useEffect(() => {
        handleProduct();
    }, [])


    let history = useHistory()

    const handleProduct = async () => {
        toggleLoading(true)
        console.log("handle product")
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };
        let boq_id = localStorage.getItem('boq_id')

        await fetch(`${view_cart_edit}/${boq_id}`, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then((result) => { 

                if (result.status) {
                    if (result.data[0] !== 'Record Not Found') {
                        let arr = result.data.map((ele, id) => ele['SKU_code'])
                        setId(arr)
                        console.log(result)
                    }
                    toggleLoading(false)
                }
                else {
                    // console.log(`error : ${result.data}`)
                    toggleLoading(false)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
                toggleLoading(false)
            });

        toggleLoading(false)
    }
    const addCart = async (value) => {
        console.log("add to cart: ", value)
        if (id.includes(value.SKU_code)) {
            console.log(id)
            configuredToastSuccess("Already Added to cart", "!!")
            return
        }

        let token = localStorage.getItem('token')
        let boq_id = localStorage.getItem('boq_id')
        var formdata = new FormData();
        formdata.append('product_id', value.product_id)
        formdata.append('boq_id', boq_id)

        var requestOptions = {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: formdata
        };

        await fetch(add_to_cart, requestOptions)
            .then(response => { 
                console.log("data = ", response.status)
                if (response.status === 401) {
                    localStorage.clear()
                    localStorage.setItem('isLoggedIn', false)
                    history.push('/')
                }
                return response.json()
            })
            .then(async (result) => {

                if (result.status) {
                    configuredToastSuccess(result.data[0], "!!")
                    await setId([...id, value.SKU_code])
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
            });
    }

    let listItem = []
    if (Object.keys(data).length === 0 && data.constructor === Object) { 
        return listItem
    }

    data.forEach(value => { 
        console.log(" display show == ", show, " ---- ", value['status_type'])

        listItem.push(
            // show.includes(value['status_type'])
                // ? 
                <div className="flex flex-col my-5" style={{ width: '90%', height: 'auto', paddingLeft: '10%' }}>
                    <div style={{ position: "relative" }}>
                        <div >
                            {/* <img src={value.image} alt="img1" /> */}
                            <Carousel image={value.image} image1={value.image1} image2={value.image2} image3={value.image3}/>

                        </div>
                        <button style={{ position: "absolute", right: 0, bottom: 0, outline: 'none',zIndex:99 }} onClick={() => addCart(value)}>
                            <img src={add} alt="add" />
                        </button>
                    </div>

                    <div className="flex justify-between product_detail_text">
                        <div>
                            <h1 className="product_txt text-xl xs:text-xs  lg:text-sm font-bold">{value.modelName}</h1>
                            <h1 className="product_txt text-lg xs:text-xs lg:text-xs">{value.SKU_code}, {value.type}</h1>
                        </div>
                        <div>
                            <h1 className="product_txt text-lg xs:text-xs lg:text-xs">&#8377;{value.Price}</h1>
                        </div>
                    </div>
                </div>
                // : <></>
        )
    })
    return listItem
}

export default Display

