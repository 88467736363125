import React, { useState, createContext } from "react";

const getLoggedIn = () => {
    try {
        const val = localStorage.getItem("filterItems");
        if (val === null) {
            return false;
        } else {
            return val === 'true' || val === true ? true : false;
        }
    } catch (err) {
        return false;
    }
};


export const Context = createContext({});
export const Provider = (props) => {
    const [triggerDataLoad, settriggerDataLoad] = useState(false);
    const [triggerImages, setTriggerImages] = useState(false)
    const toggleDataTrigger = () => settriggerDataLoad(!triggerDataLoad);
    const toggleImagesTrigger = () => setTriggerImages(!triggerImages);
    const [filterFields, setfilterFields] = useState({});
    const [images, setimages] = useState({});
    const [productData, setproductData] = useState([])

    const setImages = (data) => {
        if (data === null || data === undefined) setImages({})
        setimages(data)
    }

    const setProductData = (data) => {
        if (data === null || data === undefined) setProductData([])
        setproductData(data)
    }


    const handleFilter = (data) => {
        const filterKeys = ['type', 'config', 'Runnning_Length', 'opening', 'Finish', 'height', 'pattern', 'glassType', 'glass_thickness', "accessories"]
        filterKeys.map(item => {
            let a = new Set()
            data.forEach(ele => {
                if (ele[item]) {
                    let arr = ele[item].split(",")
                    a.add(...arr)
                }
            })
            filterFields[item] = [...a]
        })
        setfilterFields(filterFields)
    }


    return (
        <Context.Provider
            value={{
                triggerDataLoad,
                triggerImages,
                filterFields,
                images,
                productData,
                toggleImagesTrigger,
                setproductData,
                setImages,
                handleFilter,
                toggleDataTrigger,
                ...props
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export {
    Context as ProductContext,
    Provider as ProductContextProvider,
} from "./productContext.js";
