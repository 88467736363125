import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
import sld1 from '../../assets/Slider images/Slider image 01.PNG'
import sld2 from '../../assets/Slider images/Slider image 02.PNG'
import sld3 from '../../assets/Slider images/Slider image 03.PNG'
import sld4 from '../../assets/Slider images/Slider image 04.PNG'
import './style.css'


import "./style.css";
import SwiperCore, {
    Navigation, Pagination, Mousewheel, Keyboard
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const Carousel = ({ data, image, image1, image2, image3}) => {  
    return (
        <Swiper
            loop={true}
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            className="mySwiper flex justify-center">
            {
               data && data.length > 0 &&
                data.map((item, id) => (
                    <SwiperSlide key={id}><img src={item.asset} alt="slide" className="sliderImage" /></SwiperSlide>
                ))
            }
            {
                !data &&
                <>
                   {
                       image  !== "https://www.manage.sesimplify.com/product/" && 
                       <SwiperSlide><img src={image} alt="slide-2" className="sliderImage" /></SwiperSlide>
                } 
                
                {
                    image1  !== "https://www.manage.sesimplify.com/product/" && 
                    <SwiperSlide><img src={image1} alt="slide-3" className="sliderImage" /></SwiperSlide>

                }
                {
                   image2  !== "https://www.manage.sesimplify.com/product/" && 
                    <SwiperSlide><img src={image2} alt="slide-1" className="sliderImage" /></SwiperSlide>
                }
                {
                    image3  !== "https://www.manage.sesimplify.com/product/" && 
                    <SwiperSlide><img src={image3} alt="slide-1" className="sliderImage" /></SwiperSlide>
                }
                </>
            }


        </Swiper>
    )
}

export default Carousel
