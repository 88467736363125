import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Down from "../../assets/Group 52.svg";
import EditIcon from "./../../assets/edit-icon.png";
import whatsapp from "../../assets/Whatsapp icon/Whatsapp icon/whatsapp icon@2x.png";
import Navbar from "../Navbar/Navbar";
import Navigation from "../Navigation/Navigation";
import Carousel from "./Carousel";
import { add_new_BOQ, dashboardData } from "../API/urls";
import Modal from "../Modal/Modal";
import { host } from "../API/server";

const Dashboard = () => { 
  const [q, setQ] = useState("");
  const [boqData, setBoqData] = useState([]);
  const [modal, setModal] = useState(false);
  const [dbData, setDashboardData] = useState(null);
  const [banner, setBannner] = useState([]);
  const [account, setAccount] = useState();

  let uniqueBOQ = new Set();

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    let token = localStorage.getItem("token");
    let user_type = localStorage.getItem("account_status");
    setAccount(user_type);
    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(dashboardData, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        if (result.success || result.status) {
          setBoqData(result.data.user_product_details);
          setBannner(result.data.top_banner);
          setDashboardData(result.data);
          // console.log(result.data,"result.data.top_banner")
          // console.log(result.data,"result.data")
        } else {
          // alert(`${result}`)
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
      });
  };

  let history = useHistory();

  const toggleModal = () => {
    setModal(!modal);
  };

  const updateInput = async (q) => {
    if (dbData) {
      console.log(dbData);
      const filtered = dbData.user_product_details.filter((item) => {
        return (
          item["name"].toLowerCase().includes(q.toLowerCase()) ||
          JSON.stringify(item["BOQ_id"]).includes(q)
        );
      });
      await setQ(q);
      await setBoqData(filtered);
    }
  };

  const newBOQ = async () => {
    let token = localStorage.getItem("token");
    var requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(add_new_BOQ, requestOptions)
      .then((response) => {
        console.log("data = ", response.status);
        if (response.status === 401) {
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          history.push("/");
        }
        return response.json();
      })
      .then((result) => {
        if (result.success || result.status) {
          localStorage.setItem("boq_id", result.data.boq_id);
          history.push("/homepage");
        } else {
          // alert(`${result}`)
        }
      })
      .catch((error) => {
        console.log("data = ", error.status);
        // console.log(error)
      });
  };

  const SearchComponent = () => {
    return (
      <label htmlFor="search-form">
        <input
          type="search"
          name="search-form"
          id="search-form"
          className="search-input"
          placeholder="Search for..."
          value={q}
          onChange={(e) => updateInput(e.target.value)}
        />
      </label>
    );
  };

  const ButtonComponentInListingHeader = () => {
    return (
      <button
        style={{ borderRadius: 4 }}
        className="bg-black hover:bg-black-700 text-white text-l xs:text-xs font-bold py-4 px-6 xs:px-4"
        onClick={newBOQ}
      >
        Create new BOQ
      </button>
    );
  };

  const H1ComponentInListingHeader = () => {
    return <h1 className="text-3xl">Proposal</h1>;
  };

  const handleEdit = (id) => {
    localStorage.setItem("boq_id", id);
    history.push("/Cart");
  };

  return localStorage.getItem("isLoggedIn") ? (
    <div className="overflow-x-hidden overflow-y-hidden lg:h-full ">
      <Navbar />

      <div className="flex">
        <div
          className={`flex overflow-y-auto  mb-6 mainDirection mainContainer`}
        >
          <div className="flex flex-col justify-start">
            <Navigation
              modal={modal}
              toggleModal={toggleModal}
              active="nav_item_1"
            />
          </div>

          <div
            style={{ height: "auto", width: "100%" }}
            className="handleMobileView"
          >
            <div className="flex flex-col justify-center">
              <div className="pb-5 align-center">
                <Carousel data={banner.length > 0 && banner} />
              </div>
            </div>
          </div>
          <div className="overflow-y-hidden dashBoardMainListing">
            <div className="flex flex-col justify-center">
              <div className="pt-5 align-center handleDesktopView">
                <Carousel data={banner.length > 0 && banner} />
              </div>
              {/* Desktop View */}
              <div className="dashBoardMainListingHeader handleDesktopViewOfSearch">
                {H1ComponentInListingHeader()}
                <div className="flex justify-end align-center">
                  {SearchComponent()}
                  {ButtonComponentInListingHeader()}
                </div>
              </div>
              {/* Mobile View */}
              <div className="dashBoardMainListingHeader handleMobileViewOfSearch">
                <div className="section_1">
                  {H1ComponentInListingHeader()}
                  {ButtonComponentInListingHeader()}
                </div>
                <div className="section_2">{SearchComponent()}</div>
              </div>

              <div>
                {boqData.length > 0 ? (
                  boqData.map((obj, id) => {
                    let date = obj.Date.split("-");
                    let boq_id = obj.BOQ_id;
                    if (uniqueBOQ.has(obj.BOQ_id)) return <div key={id}></div>;
                    else {
                      uniqueBOQ.add(obj.BOQ_id);
                      return (
                        <>
                          {account === "3" ? (
                            <div
                              className="grid grid-cols-4 gap-1 sm:grid-cols-3 xs:grid-cols-3 xs:my-4 sm:my-4"
                              key={id}
                            >
                              {/* <div className="border-2 border-gray-400 xs:ml-4 ml-20 my-4 w-36 2xl:h-28 2xl:w-48 h-30 md:h-24 lg:h-24 xs:h-16 xs:w-28" style={{backgroundColor:'#e3e3e3', justifyContent:'center', alignItems:'center'}}> */}
                              <img
                                alt={`product-${id}`}
                                src={obj.image}
                                className="border-2 border-gray-400 my-4 2xl:h-28  h-24 sm:h-20 xs:h-16"
                                style={{
                                  backgroundColor: "#e3e3e3",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              />
                              {/* </div> */}

                              {/* DEKTOP VIEW */}
                              <div className="flex flex-col align-center handleDesktopViewOfGrid">
                                <h1 className=" text-xl md:text-md font-bold">
                                  {obj.name}
                                </h1>
                                <h2 className=" text-xl md:text-md font-bold">
                                  P-ID {obj.BOQ_id}
                                </h2>
                              </div>
                              <h1 className=" text-xl font-bold handleDesktopViewOfGrid">
                                {date[2].slice(0, 2)}/{date[1]}/
                                {date[0].slice(2, 4)} {date[2].slice(2)}
                              </h1>
                              {/* MOBILE VIEW */}
                              <div className="handleMobileViewOfGrid">
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md font-bold">
                                  {obj.name}
                                </h1>
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                  P-ID {obj.BOQ_id}
                                </h1>
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                  {date[2].slice(0, 2)}/{date[1]}/
                                  {date[0].slice(2, 4)} {date[2].slice(2, 8)}
                                </h1>
                              </div>

                              <div className="grid grid-flow-col grid-cols-3 gap-2 justify-items-end">
                                <img
                                  src={EditIcon}
                                  alt="download"
                                  className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  onClick={() => handleEdit(obj.BOQ_id)}
                                />
                                <a href={`${host}/customerpdfview/${obj.BOQ_id}`}>
                                  <img
                                    src={Down}
                                    alt="download"
                                    className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  />
                                </a>
                                <img
                                  src={whatsapp}
                                  alt="whatsapp"
                                  className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  onClick={() => {
                                    window.open(
                                      `https://api.whatsapp.com/send?phone=&text=https://www.sesimplify.com/shareCart/${boq_id}`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : account === "2"  ?(
                            <div
                              className="grid grid-cols-4 gap-1 sm:grid-cols-3 xs:grid-cols-3 xs:my-4 sm:my-4"
                              key={id}
                            >
                              {/* <div className="border-2 border-gray-400 xs:ml-4 ml-20 my-4 w-36 2xl:h-28 2xl:w-48 h-30 md:h-24 lg:h-24 xs:h-16 xs:w-28" style={{backgroundColor:'#e3e3e3', justifyContent:'center', alignItems:'center'}}> */}
                              <img
                                alt={`product-${id}`}
                                src={obj.image}
                                className="border-2 border-gray-400 my-4 2xl:h-28  h-24 sm:h-20 xs:h-16"
                                style={{
                                  backgroundColor: "#e3e3e3",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              />
                              {/* </div> */}

                              {/* DEKTOP VIEW */}
                              <div className="flex flex-col align-center handleDesktopViewOfGrid">
                                <h1 className=" text-xl md:text-md font-bold">
                                  {obj.name}
                                </h1>
                                <h2 className=" text-xl md:text-md font-bold">
                                  P-ID {obj.BOQ_id}
                                </h2>
                              </div>
                              <h1 className=" text-xl font-bold handleDesktopViewOfGrid">
                                {date[2].slice(0, 2)}/{date[1]}/
                                {date[0].slice(2, 4)} {date[2].slice(2)}
                              </h1>
                              {/* MOBILE VIEW */}
                              <div className="handleMobileViewOfGrid">
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md font-bold">
                                  {obj.name}
                                </h1>
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                  P-ID {obj.BOQ_id}
                                </h1>
                                <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                  {date[2].slice(0, 2)}/{date[1]}/
                                  {date[0].slice(2, 4)} {date[2].slice(2, 8)}
                                </h1>
                              </div>

                              <div className="grid grid-flow-col grid-cols-3 gap-2 justify-items-end">
                                <img
                                  src={EditIcon}
                                  alt="download"
                                  className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  onClick={() => handleEdit(obj.BOQ_id)}
                                />
                                <a href={`${host}/pdfview/${obj.BOQ_id}`}>
                                  <img
                                    src={Down}
                                    alt="download"
                                    className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  />
                                </a>
                                <img
                                  src={whatsapp}
                                  alt="whatsapp"
                                  className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                  onClick={() => {
                                    window.open(
                                      `https://api.whatsapp.com/send?phone=&text=https://www.sesimplify.com/shareCart/${boq_id}`
                                    );
                                  }}
                                />
                              </div>
                            </div>

                          )
                          : (
                            <div
                            className="grid grid-cols-4 gap-1 sm:grid-cols-3 xs:grid-cols-3 xs:my-4 sm:my-4"
                            key={id}
                          >
                            {/* <div className="border-2 border-gray-400 xs:ml-4 ml-20 my-4 w-36 2xl:h-28 2xl:w-48 h-30 md:h-24 lg:h-24 xs:h-16 xs:w-28" style={{backgroundColor:'#e3e3e3', justifyContent:'center', alignItems:'center'}}> */}
                            <img
                              alt={`product-${id}`}
                              src={obj.image}
                              className="border-2 border-gray-400 my-4 2xl:h-28  h-24 sm:h-20 xs:h-16"
                              style={{
                                backgroundColor: "#e3e3e3",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            />
                            {/* </div> */}

                            {/* DEKTOP VIEW */}
                            <div className="flex flex-col align-center handleDesktopViewOfGrid">
                              <h1 className=" text-xl md:text-md font-bold">
                                {obj.name}
                              </h1>
                              <h2 className=" text-xl md:text-md font-bold">
                                P-ID {obj.BOQ_id}
                              </h2>
                            </div>
                            <h1 className=" text-xl font-bold handleDesktopViewOfGrid">
                              {date[2].slice(0, 2)}/{date[1]}/
                              {date[0].slice(2, 4)} {date[2].slice(2)}
                            </h1>
                            {/* MOBILE VIEW */}
                            <div className="handleMobileViewOfGrid">
                              <h1 className="text-md xs:text-sm sm:text-md md:text-md font-bold">
                                {obj.name}
                              </h1>
                              <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                P-ID {obj.BOQ_id}
                              </h1>
                              <h1 className="text-md xs:text-sm sm:text-md md:text-md py-0">
                                {date[2].slice(0, 2)}/{date[1]}/
                                {date[0].slice(2, 4)} {date[2].slice(2, 8)}
                              </h1>
                            </div>

                            <div className="grid grid-flow-col grid-cols-3 gap-2 justify-items-end">
                              <img
                                src={EditIcon}
                                alt="download"
                                className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                onClick={() => handleEdit(obj.BOQ_id)}
                              />
                              <a href={`${host}/pdfview/${obj.BOQ_id}`}>
                                <img
                                  src={Down}
                                  alt="download"
                                  className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                />
                              </a>
                              <img
                                src={whatsapp}
                                alt="whatsapp"
                                className="xs:h-8 xs:w-8 h-25 w-10 xs:my-4 sm:my-4"
                                onClick={() => {
                                  window.open(
                                    `https://api.whatsapp.com/send?phone=&text=https://www.sesimplify.com/shareCart/${boq_id}`
                                  );
                                }}
                              />
                            </div>
                          </div>
                          )
                          }
                        </>
                      );
                    }
                  })
                ) : (
                  <div className="text-center text-xl my-7 text-gray-500">
                    No BOQ Found!!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && <Modal modal={modal} toggleModal={toggleModal} />}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Dashboard;
