import React, { Component } from "react";
import "./register.css"
import { allDealer } from "../API/urls";

class Autocomplete extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            dealer: []
        };

    }

    componentDidMount() {
        this.getDealerData()
    }

    getDealerData = async () => {
        let token = localStorage.getItem('token')
        var requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            },
        };

        await fetch(allDealer, requestOptions)
            .then(response => {
                console.log("data = ", response.status)
                return response.json()
            })
            .then((result) => {
                if (result.success || result.status) {
                    console.log("dealers data")
                    this.setState({ dealer: result.data })
                }
                else {
                    // alert(`${result}`)
                }
            })
            .catch((error) => {
                console.log("data = ", error.status)
                // console.log(error)
            });
    }

    onChange = (e) => {
        const suggestions = this.state.dealer;
        const userInput = e.currentTarget.value;
        // console.log("suggestions === ", suggestions)
        const filteredSuggestions = suggestions.filter(
            (suggestion) =>
                suggestion['name'].toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        // console.log(filteredSuggestions)

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
        });

        this.props.setInput(e.currentTarget.value)
    };

    onClick = (e) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,

        });
        this.props.setInput(e['name'])
        this.props.setDealerId(e['dealer_id'])
    };

    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
            });

            this.props.setInput(filteredSuggestions[activeSuggestion])
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                filteredSuggestions,
                showSuggestions,
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && this.props.input) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <div
                        className="suggestions shadow appearance-none border-gray-400 border-2 text-left text-black-700 leading-tight focus:shadow-outline w-full"
                    >
                        {filteredSuggestions.map((suggestion, index) => {
                            return (
                                <div className='list' onClick={() => onClick(suggestion)} key={index}>
                                    <div style={{ fontSize: 16 }}>{suggestion['name']}</div>
                                    <div style={{ fontSize: 14 }}>{suggestion['dealer_city']}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions, you're on your own!</em>
                    </div>
                );
            }
        }

        return (
            <>
                    <input
                        className={this.props.className}
                        type="text"
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={this.props.input}
                        placeholder={"Dealer Name"}
                    />

                    {suggestionsListComponent}

            </>
        );
    }
}

export default Autocomplete;
