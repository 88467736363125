import React from 'react'
import {Link} from 'react-router-dom';
import pict from '../../assets/Dashboard- Dealer/KohlerLogo.png'

const Navbar = ({admin}) => {
    return (
        <div className=" 2xl:flex 2xl:justify-center 2xl:w-screen">
            <nav className="grid grid-cols-2 2xl:w-screen 2xl:px-8 2xl:flex 2xl:justify-between xl:flex xl:justify-between lg:flex lg:justify-between md:flex md:justify-between sm:flex sm:justify-between xs:flex xs:justify-between" style={{height:'100px'}}>
                <div className="col-span-1 px-4" style={{width:"180px" , height:'100px', display: 'flex',alignItems: 'center'}}>
                    <Link to={admin ? "/admindash" : "/dashboard"}>
                        <img src={pict}  alt="logo" />
                    </Link>
                </div>
                <div className="col-span-2 2xl:flex  2xl:justify-evenly 2xl:self-center xl:flex xl:justify-evenly xl:m-6 lg:flex lg:justify-evenly lg:m-6 md:flex md:justify-evenly md:m-6 sm:flex sm:justify-evenly sm:m-6 xs:flex xs:justify-evenly xs:m-6">
                    <span>
                        <Link to="/Cart">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mr-4 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </Link>
                    </span>
                    <span>
                        <Link to="/dealer-info">
                            <svg
                                className="fill-current h-10 w-10 mb-2  self-center"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </Link>
                    </span>

                </div>
            </nav>
        </div>
    )
}

export default Navbar
